import { Component, OnInit, NgZone } from '@angular/core';
import { APIService } from '../../api.service';

@Component({
  selector: 'header-carousel',
  templateUrl: 'header-carousel.component.html',
  styleUrls: ['header-carousel.component.scss']
})
export class HeaderCarouselComponent implements OnInit {
  posts = [];
  index = 0;
  reference: any;

  constructor (private API: APIService, private zone: NgZone) {
    API.GET('blogs/posts/featured').subscribe(posts => this.posts = posts)
  }

  ngOnInit () {
    this.focus(0)
  }

  focus (index) {
    clearInterval(this.reference);
    this.reference = setInterval (() => {
      this.focus(this.index + 1);
    }, 3000);

    if (index < 0 || index > this.posts.length - 1) {
      index = 0;
    }

    this.index = index;
  }
}
