import { Directive, ElementRef, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';

// declare var $: JQueryStatic;
declare var $: any;

@Directive({
  selector: '[infiniteScroll]'
})

export class InfiniteScrollDirective implements AfterViewInit, OnDestroy {
  @Output() elementReached = new EventEmitter();
  element: HTMLElement;

  constructor(el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngAfterViewInit() {
    $(window).scroll(evt => this.onScroll(evt));
  }

  ngOnDestroy() {
    $(window).off('scroll', this.onScroll);
  }

  onScroll(evt: JQueryEventObject) {
    let elemTop = this.element.getBoundingClientRect().top;
    let elemBottom = this.element.getBoundingClientRect().bottom;

    if ((elemTop >= 0) && (elemBottom <= window.innerHeight)) {
      this.elementReached.emit(true);
    }
  }
}
