import { Component, Input } from '@angular/core';
import { APIService } from '../../api.service';

@Component({
  selector: 'post-inline',
  templateUrl: 'post.inline.component.html',
  styleUrls: ['post.inline.component.scss']
})
export class PostInlineComponent {
  @Input() post: any;

  constructor (private API: APIService) {
  }

  getTextOverview (text, ellipsisLength?) {
    text = this.API.trimHTML(text);

    let regex = /^((?:\S+\s+){40}\S+).*/;

    let result = regex.exec(text);
    if (result !== null) {
      return ellipsisLength ? result[1].substring(0, ellipsisLength) : result[1];
    } else {
      return ellipsisLength ? text.substring(0, ellipsisLength) : text;
    }
  }
}
