import {Component, OnInit, ViewChild} from '@angular/core';
import {APIService} from '../../api.service';
import {SeoService} from '../../services';
import {map} from 'rxjs/operator/map';
import {isNumber} from 'util';

const MONTHS = [
  'Januari',
  'Februari',
  'Mars',
  'April',
  'Maj',
  'Juni',
  'Juli',
  'Augusti',
  'September',
  'Oktober',
  'November',
  'December'
];

interface Year {
  year: number;
  image: string;
}

interface Month {
  year: number;
  month: number;
  monthText: string;
}

class PotmYear {
  year: number;
  image: string;
  months: Map<number, PotmMonth>;
}

class PotmMonth {
  year: number;
  month: number;
  monthText: string;
  potm: Potm[];
}

interface Conference {
  name: string,
  id: string,
  priority: string
}

interface League {
  title: string;
  id: string,
  priority: number,
  name: string,
  slug: string,
  conferences: [Conference]
}

class Potm {
  id: string;
  date: string;
  league: League;
  leagueBackup: League;
  player: {
    age: number
    club: string
    conference: string
    id: string
    image: string
    league: string
    name: string
    number: number
    position: string
  };
  slug: string;
  motivation: string;
}

interface AvailableMonths {
  index: number;
  text: string;
  disabled: boolean;
}

@Component({
  selector: 'player-of-the-month-list',
  templateUrl: './player-of-the-month-list.component.html',
  styleUrls: ['./player-of-the-month-list.component.scss']
})
export class PlayerOfTheMonthListComponent {
  @ViewChild('anchor') anchor;
  playersOfTheMonth: Potm[] = [];
  filteredPlayersOfTheMonth: Potm[] = [];

  // Filters
  filteredYear: number;
  filteredMonth: number;

  years: Map<number, Year> = new Map();
  months: Map<string, Month> = new Map();

  availableMonths = [];

  potmYearsMap: Map<number, PotmYear> = new Map();
  yearList: PotmYear[] = [];

  // State
  potmIsEmpty = false;

  constructor(private API: APIService, private seoService: SeoService) {
    seoService.setTitle('Månadens spelare - Hockeymagasinet');
    seoService.setMetaDescription(
      'Tävling där Hockeymagasinet tar chansen att hylla de vi tycker har varit mest framträdande varje månad i Juniorligan.'
    );

    API.GET('players-of-the-month').subscribe(playersOfTheMonth => {
      if (playersOfTheMonth.length < 1) {
        this.potmIsEmpty = true;
        return;
      }

      this.playersOfTheMonth = playersOfTheMonth;

      this.potmYearsMap = this.getPotmYearsMap(playersOfTheMonth);
      this.yearList = this.getPotmYearList(this.potmYearsMap);

      this.filteredYear = this.latestYear;
      this.filteredMonth = this.latestMonth;

      this.updateFilteredPlayersOfTheMonth();
      this.updateAvailableMonths();
    });
  }

  public onChangeMonth(newMonth: string) {
    this.filteredMonth = parseInt(newMonth, 10);
    this.updateFilteredPlayersOfTheMonth();
  }

  public getHighlightDate(dateString: string) {
    let date = new Date(dateString);
    let month = MONTHS[date.getMonth()];
    let year = date.getFullYear();

    return `${month} ${year}`;
  }

  public onChangeYear(year: number) {
    this.scrollToTop();
    this.filteredYear = year;
    this.filteredMonth = this.latestMonth;
    this.updateFilteredPlayersOfTheMonth();
    this.updateAvailableMonths();
  }

  public get showYearList() {
    return this.yearList.length > 1;
  }

  private updateAvailableMonths(): Month[] {
    let year = this.potmYearsMap.get(this.filteredYear);
    if (!year) {
      this.availableMonths = [];
      return;
    }

    let availableMonths: AvailableMonths[] = [];

    MONTHS.forEach((text, index) => {
      availableMonths.push({
        index,
        text,
        disabled: !year.months.has(index)
      });
    });

    this.availableMonths = availableMonths;
  }

  private getPotmYearsMap(playersOfTheMonth): Map<number, PotmYear> {
    let potmYears = new Map<number, PotmYear>();

    playersOfTheMonth.forEach(potm => {
      let date = new Date(potm.date);
      let year = date.getFullYear();
      let month = date.getMonth();

      // Sets the year
      let potmYear = potmYears.get(year);

      if (!potmYear) {
        potmYear = new PotmYear();
        potmYear.year = year;
        potmYear.image = potm.player.image;
        potmYear.months = new Map();
        potmYears.set(year, potmYear);
      }

      // Sets the month
      let potmMonth = potmYear.months.get(month);

      if (!potmMonth) {
        potmMonth = new PotmMonth();
        potmMonth.month = month;
        potmMonth.monthText = MONTHS[month];
        potmMonth.year = year;
        potmMonth.potm = [];

        potmYear.months.set(month, potmMonth);
      }

      potmMonth.potm.push(potm);
    });
    return potmYears;
  }

  private updateFilteredPlayersOfTheMonth() {
    let year = this.potmYearsMap.get(this.filteredYear);
    if (!year) {
      this.filteredPlayersOfTheMonth = [];
    }

    let month = year.months.get(this.filteredMonth);
    this.filteredPlayersOfTheMonth = month ? month.potm : [];
    this.filteredPlayersOfTheMonth = this.addLeagueTitle(this.filteredPlayersOfTheMonth);
    this.filteredPlayersOfTheMonth = this.sortByLeague(this.filteredPlayersOfTheMonth)
  }

  private sortByLeague(players: Potm[]): Potm[] {

    return players.sort((p1, p2) => {
      // This is to ensure that an unknown league or a league without priority is placed last
      let p1_priority = this.getLeaguePriority(p1);
      let p2_priority = this.getLeaguePriority(p2);

      /**
       * Use the conference priority if the league priority is the same.
       */
      if (p1_priority - p2_priority === 0) {
        p1_priority = this.getConferencePriority(p1);
        p2_priority = this.getConferencePriority(p2);
      }
      return p1_priority - p2_priority;
    });
  }

  private getLeaguePriority(player: Potm) {
    if (player.league) {
      return this.getPriority(player.league.priority);
    }
    return this.getPriority();
  }

  /**
   * get the priority from input. Will return a default priority if input isn't a number or if no input is given.
   * @param priority The value to check
   */
  private getPriority(priority = null) {
    return isNumber(priority) ? priority : 1000000;
  }

  /**
   * Get the priority of the players conference. If the player has no conference or the conference doesn't exist a default priority
   * will be returned.
   * @param player The player to get the conference priority for.
   */
  private getConferencePriority(player: Potm) {
    let conf = this.getPlayerConference(player);
    if (conf) {
      return this.getPriority(conf.priority);
    } else {
      return this.getPriority();
    }
  }

  private getPotmYearList(potmYearsMap: Map<number, PotmYear>): PotmYear[] {
    let yearList = [];
    potmYearsMap.forEach(year => {
      yearList.push(year);
    });

    return yearList;
  }

  private scrollToTop() {
    this.anchor.nativeElement.scrollIntoView({
      block: 'end',
      behavior: 'auto'
    });
  }

  private get latestYear() {
    if (this.yearList.length > 0) {
      return this.yearList[0].year;
    }

    let date = new Date();
    return date.getFullYear();
  }

  private get latestMonth(): number {
    if (!this.filteredYear || !this.potmYearsMap.get(this.filteredYear)) {
      let date = new Date();
      return date.getMonth();
    }
    return this.potmYearsMap
      .get(this.filteredYear)
      .months.values()
      .next().value.month;
  }

  /**
   * Will ad a title to each players league. Will attempt to use as specific and fresh data as possible. Starting with the league
   * conference title, then the fresh league title and lastly the league backup title. If no title can be found it will be set to 'Okänt'.
   * @param players The players to add the league title to.
   */
  private addLeagueTitle(players: Potm[]): Potm[] {
    return players.map(player => {
      const conference = this.getPlayerConference(player);

      if (conference !== undefined) {
        player.league.title = conference.name;
      } else if (player.league) {
        player.league.title = player.league.name
      } else if (player.leagueBackup) {
        player.league.title = player.leagueBackup.title
      } else {
        player.league.title = 'Okänt';
      }
      return player;
    });
  }

  private getPlayerConference(player: Potm): Conference {
    let league = player.league ? player.league : player.leagueBackup;
    if (league) {
      return league.conferences.find(conf => {
        return conf.id === player.player.conference
      });
    }
    return undefined
  }
}
