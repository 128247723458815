import { Component, Input } from '@angular/core';
import { APIService } from '../../api.service';

@Component({
  selector: 'division-news',
  templateUrl: 'division.news.component.html',
  styleUrls: ['division.news.component.scss'],
})
export class DivisionNewsComponent {
  @Input() league: any;
  @Input() national: any;
  @Input() limit: number;
  @Input() expandable: boolean;

  alreadyLoaded:number = 9;
  hasMoreItems:boolean = true;

  constructor (private API: APIService) { }

  getItems () {
    let items = [];

    if (this.league) {
      items = this.league.posts.map(post => {
        return post;
      });
    }

    if (this.national) {
      items = this.national.posts.map(post => {
        return post;
      });
    }

    items.shift();
    return items;
  }


  loadMoreArticles (articles) {
    const LIMIT = 8;

    this.API.GET('articles', {
      tag: this.league ? this.league.name : this.national.name,
      limit: LIMIT,
      offset: this.alreadyLoaded
    }).subscribe(items => {
      if (items.length < LIMIT - 1) {
        this.hasMoreItems = false
      }


      articles.push(...items);
      this.alreadyLoaded += LIMIT
    })
  }
}
