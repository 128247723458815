import { Component } from '@angular/core';
import { APIService } from '../../api.service';

const dayInMilliseconds = 1000 * 60 * 60 * 24;

@Component({
  selector: 'bloggers',
  templateUrl: 'bloggers.component.html',
  styleUrls: ['bloggers.component.scss']
})
export class BloggersComponent {
  latestBlogPosts: [any];

  constructor(private API: APIService) {
    API.GET('blogs/posts/latest').subscribe(blogPosts => {
      if (blogPosts.length > 0) {
        let modifier = 1;
        do {
          let fromDate = new Date(
            new Date().getTime() - (30 + modifier) * dayInMilliseconds
          );

          this.latestBlogPosts = blogPosts.filter(blogPost => {
            let blogPostDate = new Date(blogPost.published);
            return blogPostDate > fromDate;
          });
          modifier++;
        } while (this.latestBlogPosts.length < 3 && modifier < 335);
      }
    });
  }
}
