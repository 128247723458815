<div class="award-list">
  <h4>Awards</h4>
  <img
    class="logo"
    src="/assets/images/awards-logo.svg"
  />

  <h5 class="title">Hockeymagasinet Awards</h5>
  <p class="info">
    Hockeymagasinet Awards är juniorhockeyns egen prestigefulla gala. Sedan dess inträde år 2011 har
    Hockeymagasinets egen redaktion tittat på samtliga spelares insatser och prestationer under säsongen och
    utifrån det delat ut åtta priser efter säsongen. Från början gällde galan enbart J20 SuperElit men numera är
    även J18-Elit en del av den. Galans syfte är att belysa varje säsongs främsta prestationer och på så sätt
    förstärka juniorhockeyn som produkt.
  </p>

  <div class="league-selection">
    <button
      class="button"
      *ngFor="let league of getLeagueList()"
      [class.active]="filters.league === league"
      (click)="setLeague(league)"
    >{{
      league.name }}</button>
  </div>

  <div
    class="awards"
    *ngIf="awards.length"
    #anchor
  >
    <div *ngFor="let active of getFilteredItems()">
      <award
        [title]="active.league.name"
        [imageUrl]="active.player.image"
        [imageHighlightText]="'Vinnare ' + active.year"
        [logoUrl]="'/assets/images/awards-logo.svg'"
        [categoryText]="active.category"
        [playerName]="active.player.name"
        [text]="active.text"
      ></award>
    </div>

    <h5 class="title">Vinnare från tidigare år</h5>
    <div class="year-overview">
      <div
        class="year"
        *ngFor="let year of getYearList()"
        [class.active]="filters.year === year"
        (click)="setYear(year); scrollToTop()"
        [style.background-image]="'url(' + getPhoto(year) + ')'"
      >
        <div class="text">{{ year }}</div>
      </div>
    </div>
  </div>
</div>
