import { Injectable } from '@angular/core';
import { CookieService } from '../services/cookie.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs';

@Injectable()
export class PrivacyService {
  private GDPRIsApproved: boolean;
  private GDPRCookie: any;
   termsLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  GDPRApproval$: Subject<boolean>;

  constructor(private cookieService: CookieService) {
    const cookie_value = this.cookieService.readCookie('hm_gdpr');
    if (cookie_value === null) {
      this.GDPRIsApproved = null;
    } else {
      this.GDPRIsApproved = cookie_value !== '0';
    }
    this.GDPRApproval$ = new Subject();
    this.GDPRApproval$.next(this.GDPRIsApproved);
  }

  /**
   * Check if GDPR has been accepted. True if it is approved, false if denied and null if no choice has been made.
   */
  checkGDPR(): boolean {
    return this.GDPRIsApproved;
  }

  /**
   * Sets the gdpr cookie to approved and change the gdpr state to approved.
   */
  approveGDPR() {
    this.GDPRIsApproved = true;
    this.GDPRCookie = this.cookieService.createCookie('hm_gdpr', '1', 90);
    this.GDPRApproval$.next(this.GDPRIsApproved);
  }

  /**
   * Sets the gdpr cookie to denied and change the gdpr state to denied.
   */
  disapproveGDPR() {
    this.GDPRIsApproved = false;
    this.GDPRCookie = this.cookieService.createCookie('hm_gdpr', '0', 90);
    this.GDPRApproval$.next(this.GDPRIsApproved);
  }

  loadTerms() {
    this.termsLoadedSubject.next(true);
  }

  destroyTerms() {
    this.termsLoadedSubject.next(false);
  }
}
