import { Component, Input } from '@angular/core';

import * as moment from 'moment';
import 'moment/locale/sv';

@Component({
  selector: 'timestamp',
  template: `<h2 class="timestamp">{{ getPrettyTimestamp(time) }}</h2>`,
  styleUrls: ['timestamp.component.scss']
})
export class TimestampComponent {
  @Input() time: string;
  @Input() friendly: boolean;

  constructor() {
    moment.locale('sv')
  }

  getPrettyTimestamp (timestamp) {
    if (this.friendly) {
      const epoch = +new Date(timestamp)
      const now   = +new Date()
      const fourDays = 345600000

      if (epoch + fourDays > now) {
        return timestamp ? moment(timestamp).calendar() : ''
      }
    }

    return timestamp ? moment(timestamp).format('LLLL') : ''
  }
}
