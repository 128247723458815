<div
  *ngIf="visible"
  class="modal-container"
>
  <div
    class="close"
    (click)="close()"
  ></div>

  <div class="modal-body">
    <div class="modal-content">
      <sellbranch-ad
        (interstitialLoaded)="isLoaded($event)"
        adName='interstitial-desktop'
      ></sellbranch-ad>
      <sellbranch-ad
        (interstitialLoaded)="isLoaded($event)"
        adName='interstitial-mobil'
      ></sellbranch-ad>
    </div>
  </div>
  <div class="closeContainer">
    <div
      class="closeText"
      (click)="close()"
    >
      <small>STÄNG</small>
    </div>
  </div>
</div>
<div
  *ngIf="visible"
  class="modal-background"
  (click)="close()"
></div>
