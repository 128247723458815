<div class="card card--large" *ngIf="size === 'large'">
  <div class="photo" [style.background-image]="'url(' + post.images[0]?.url + ')'"></div>
  <div class="info">
    <a [routerLink]="['/article', post.slug]"><h5>{{ post.title }}</h5></a>
    <!-- <div class="text--6">
      <a [routerLink]="['/article', post.slug]">Läs mer</a>
    </div> -->
  </div>
</div>

<div class="card card--medium" *ngIf="size === 'medium'">
  <div class="photo" [style.background-image]="'url(' + post.images[0]?.url + ')'"></div>

  <div class="info">
    <div class="text--2">
      <a [routerLink]="['/article', post.slug]">{{ post.title }}</a>
    </div>
  </div>
</div>
