import { Injectable } from '@angular/core';
import { APIService } from '../api.service';

@Injectable()
export class RelatedService {
  private tags: string[];
  private result: any[];

  constructor (private API: APIService) {

  }

  getRelatedPosts () {
    return this.result
  }

  getTags () {
    return this.tags
  }

  setTags (tags: any, filterId: string = null): void {
    this.tags = tags;

    if (!tags || !tags.length) {
      return
    }

    let searchString = this.tags.join(' ');

    this.API.GET('search', {
      searchParams: searchString,
      limit: 6
    }).subscribe(result => this.result = result.filter(post => {
      return post.id !== filterId;
    }))
  }
}
