<div class="content">
  <div class="wrapper">
    <div class="main">
      <h4 #anchor>Sök</h4>
      <!-- <div class="google-media-search" [innerHTML]="gcsesearch"></div> -->
      <form
        class="form"
        (ngSubmit)="onSubmit()"
      >
        <div class="searchfield">
          <input
            [(ngModel)]="text"
            name="search"
            id="text"
            type="text"
            placeholder="Söktext..."
          >
          <button
            id="submit"
            type="submit"
          ><i
              aria-hidden="true"
              class="fa fa-search"
            ></i></button>

        </div>

        <div class="buttons">
          <input
            type="radio"
            [(ngModel)]="sorting"
            value="relevance"
            name="relevance"
            id="relevance"
          >
          <label for="relevance">Relevans</label>

          <input
            type="radio"
            [(ngModel)]="sorting"
            value="date"
            name="relevance"
            id="date"
          >
          <label for="date">Datum</label>
        </div>

      </form>

      <div class="results">
        <spinner *ngIf="searching"></spinner>

        <div
          class="empty"
          *ngIf="!searching && !hasResults"
        >
          Inga resultat funna!
        </div>

        <div
          class="container"
          *ngIf="pages?.length"
        >

          <div
            class="item"
            *ngFor="let item of pages[page]"
          >

            <div class="content">
              <a [routerLink]="getRouteForItem(item)">
                <h4 class="title">{{ item.title }}</h4>
              </a>

              <span class="timestamp">{{ getPrettyTimestamp(item.published) }}</span>

              <div class="text">{{ getTextOverview(item.text) }}
                <a
                  class="show-more"
                  [routerLink]="getRouteForItem(item)"
                >Läs mer</a>
              </div>
            </div>

            <div
              class="picture"
              *ngIf="item?.images?.length"
              [style.background-image]="'url(' + item.images[0].url + ')'"
            ></div>
          </div>

          <div
            class="pagination"
            *ngIf="pages.length > 1"
          >
            <button
              class="previous"
              (click)="setPage(page - 1)"
              [disabled]="page === 0"
            ><i
                class="fa fa-angle-left"
                aria-hidden="true"
              ></i></button>
            <span
              *ngFor="let _ of pages; let i = index"
              (click)="setPage(i)"
              [class.active]="i === page"
            >{{ i + 1 }}</span>
            <button
              class="next"
              (click)="setPage(page + 1)"
              [disabled]="page === pages.length - 1"
            ><i
                class="fa fa-angle-right"
                aria-hidden="true"
              ></i></button>
          </div>

        </div>
      </div>

    </div>



    <div class="side">
      <!-- <hottest-articles></hottest-articles> -->
      <!-- <app-notice></app-notice> -->
      <sellbranch-ad adName='insider-1'></sellbranch-ad>
      <sellbranch-ad adName='mobil-2'></sellbranch-ad>
      <!-- <ad identifier="div-gpt-ad-123456789-3"></ad> -->
      <bloggers></bloggers>
      <!-- <app-notice></app-notice> -->
      <sellbranch-ad adName='insider-2'></sellbranch-ad>
      <sellbranch-ad adName='mobil-3'></sellbranch-ad>
      <!-- <ad identifier="div-gpt-ad-123456789-4"></ad> -->
    </div>
  </div>

</div>
