

<div class="bloggers">
  <h4>Bloggare</h4>

  <spinner *ngIf="!latestBlogPosts?.length"></spinner>

  <div class="blog" *ngFor="let blogPost of latestBlogPosts">
    <div class="profile-picture" [routerLink]="['/blogs', blogPost.blogSlug]" [style.background-image]="'url(' + (blogPost.author.image | medium) + ')'"></div>

    <div class="container">
      <span class="author" [routerLink]="['/blogs', blogPost.blogSlug]">{{ blogPost?.author.name }}</span>
      <span class="title">{{ blogPost?.title }}</span>
    </div>
  </div>

  <div class="call-to-action">
    Vill du blogga på Hockeymagasinet.com?
    <br>
    <a [routerLink]="['/about']">Hör av dig till oss!</a>
  </div>

</div>
