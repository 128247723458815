import { Component, Input } from '@angular/core';

@Component({
  selector: 'tag',
  template: `<div class="tag" [routerLink]="['/search', text]">{{ text }}</div>`,
  styleUrls: ['tag.component.scss']
})
export class TagComponent {
  @Input() text: string;

  constructor() { }
}
