import { Component } from '@angular/core';
import { APIService } from '../../api.service';

@Component({
  selector: 'hottest-articles',
  templateUrl: 'hottest.articles.component.html',
  styleUrls: ['hottest.articles.component.scss']
})
export class HottestArticlesComponent {
  articles: [any];

  constructor(private API: APIService) {
    API.GET('articles/hottest').subscribe(articles => this.articles = articles)
  }
}
