import { Router, NavigationEnd } from '@angular/router';
import 'rxjs/add/operator/filter';
import { Injectable } from '@angular/core';
import { SellbranchMappingInterface } from '../interfaces/sellbranch-mapping-interface';
import { PrivacyService } from './privacy.service';

@Injectable()
export class AdService {
  private mappings = {
    mobile: {
      flow: {
        Flow_1_Mobile: {
          identifier: '29590f5b-93a9-4664-808a-45cc0fbcec0f',
          placement: ['1222517', null]
        },
        Flow_2_Mobile: {
          identifier: '084f1506-2bb6-4219-80ab-869e5c39ba47',
          placement: ['1222518', null]
        },
        Flow_3_Mobile: {
          identifier: '0c4e7856-2d70-44a9-bf49-3ae225c8602b',
          placement: ['1222520', null]
        },
        Flow_4_Mobile: {
          identifier: '4d4f544f-3bc7-4678-a127-30a6d900f5eb',
          placement: ['1222522', null]
        },
        Flow_5_Mobile: {
          identifier: 'a426e886-90aa-410d-aed8-40330099b01c',
          placement: ['1229377', null]
        },
        Flow_6_Mobile: {
          identifier: 'e3517826-0ed8-46b6-b514-0690822d6218',
          placement: ['1229378', null]
        },
        Flow_7_Mobile: {
          identifier: 'e863adf2-09af-4e42-9588-34683121deea',
          placement: ['1229379', null]
        },
        Flow_8_Mobile: {
          identifier: '8619f37c-98d4-4691-ae8d-21be98902733',
          placement: ['1229380', null]
        }
      },
      interstitial: {
        'interstitial-mobil': {
          identifier: 'e6648e4c-8ff3-4adf-b9fb-f879fe8380c8',
          placement: ['1146647', null],
          metricsTag: null
        }
      },
      general: {
        'mobil-1': {
          identifier: '109716d0-2656-4664-9186-bba7111a4868',
          placement: ['1124936', null],
          metricsTag:
            'https://s407.meetrics.net/bb-mx/prime/mtrcs_783879.js?pjid=783879&place=11124936&cpid=Dohi_publishing&site=Hockeymagasinet.se&adc=Mobile&cb=1124936&size=320x320',
          fixable: false
        },
        'mobil-2': {
          identifier: '98d2d573-0887-4c57-8129-0a8c071864f4',
          placement: ['1124937', null],
          metricsTag:
            'https://s407.meetrics.net/bb-mx/prime/mtrcs_783879.js?pjid=783879&place=1124937&cpid=Dohi_publishing&site=Hockeymagasinet.se&adc=Mobile&cb=1124937&size=320x320'
        },
        'mobil-3': {
          identifier: '9c7ad23f-fb62-4b42-903c-d139442ebea2',
          placement: ['1124938', null],
          metricsTag: null
        },
        'mobil-4': {
          identifier: 'bc221605-0423-4b96-886d-f4378f1c97e6',
          placement: ['1124939', null],
          metricsTag:
            'https://s407.meetrics.net/bb-mx/prime/mtrcs_783879.js?pjid=783879&place=1124939&cpid=Dohi_publishing&site=Hockeymagasinet.se&adc=Mobile&cb=1124939&size=320x320'
        },
        'mobil-5': {
          identifier: '558c8b37-387c-4ad3-8156-9952d259b093',
          placement: ['1147455', null],
          metricsTag:
            'https://s407.meetrics.net/bb-mx/prime/mtrcs_783879.js?pjid=783879&place=1124939&cpid=Dohi_publishing&site=Hockeymagasinet.se&adc=Mobile&cb=1147455&size=320x320'
        },
        'mobil-6': {
          identifier: 'bcc84bec-661e-4a9e-bfac-5b333d18a7b5',
          placement: ['1147456', null],
          metricsTag:
            'https://s407.meetrics.net/bb-mx/prime/mtrcs_783879.js?pjid=783879&place=1124939&cpid=Dohi_publishing&site=Hockeymagasinet.se&adc=Mobile&cb=1147456&size=320x320'
        }
      }
    },
    desktop: {
      flow: {
        Flow_1_Desktop: {
          identifier: '8ab008e5-4c9c-4015-b4f7-e3bb13a8a230',
          placement: [null, '1222509']
        },
        Flow_2_Desktop: {
          identifier: 'd0f75bc1-923e-423d-ac94-feeea9c88dd0',
          placement: [null, '1222511']
        },
        Flow_3_Desktop: {
          identifier: 'af088c8d-9410-42a9-8d28-bd1382148f48',
          placement: [null, '1222512']
        },
        Flow_4_Desktop: {
          identifier: '58381cd2-0cd4-461a-9703-0e1711c79713',
          placement: [null, '1222514']
        },
        Flow_5_Desktop: {
          identifier: '99be4c70-4adb-4625-81b9-018ba9abde71',
          placement: [null, '1229381']
        },
        Flow_6_Desktop: {
          identifier: 'e3061aef-53cb-44c6-806b-b0628f1ca50e',
          placement: [null, '1229382']
        },
        Flow_7_Desktop: {
          identifier: '494827b3-ae22-43ac-af91-9b100cce8bc4',
          placement: [null, '1229383']
        },
        Flow_8_Desktop: {
          identifier: '80d225f9-1842-422a-8c3c-b8669187a09c',
          placement: [null, '1229385']
        }
      },
      interstitial: {
        'interstitial-desktop': {
          identifier: '8abcd74d-0371-42cb-bd9f-2a1a2dc47e69',
          placement: [null, '1146646'],
          metricsTag: null
        }
      },
      widescreen: {
        'widescreen-1': {
          identifier: 'ee4d8dd3-1062-439e-af1a-a4e76008be47',
          placement: [null, '1124942'],
          metricsTag:
            'https://s407.meetrics.net/bb-mx/prime/mtrcs_783879.js?pjid=783879&place=1124942&cpid=Dohi_publishing&site=Hockeymagasinet.se&adc=Desktop&cb=1124942&size=250x360',
          fixable: false,
          releasable: false
        },
        'widescreen-2': {
          identifier: '90b70cbf-b6b4-4536-8dd6-a00fe956723e',
          placement: [null, '1124943'],
          metricsTag:
            'https://s407.meetrics.net/bb-mx/prime/mtrcs_783879.js?pjid=783879&place=1124943&cpid=Dohi_publishing&site=Hockeymagasinet.se&adc=Desktop&cb=1124943&size=250x360',
          fixable: true
        }
      },
      panorama: {
        'panorama-top': {
          identifier: '48bb6000-209b-46a4-b250-a61144c5dd0c',
          placement: [null, '1124940'],
          metricsTag:
            'https://s407.meetrics.net/bb-mx/prime/mtrcs_783879.js?pjid=783879&place=1124940&cpid=Dohi_publishing&site=Hockeymagasinet.se&adc=Desktop&cb=1124940&size=980x240',
          fixable: true,
          releasable: true
        },
        'panorama-mid': {
          identifier: 'd7c798c7-8e52-436e-9486-4287d9ad4fa0',
          placement: [null, '1140687'],
          metricsTag: null
        },
        'panorama-bottom': {
          identifier: '12e337a9-bf1c-442a-a853-f92c9085e174',
          placement: [null, '1124941'],
          metricsTag:
            'https://s407.meetrics.net/bb-mx/prime/mtrcs_783879.js?pjid=783879&place=1124941&cpid=Dohi_publishing&site=Hockeymagasinet.se&adc=Desktop&cb=1124941&size=980x240'
        }
      },
      inside: {
        'insider-1': {
          identifier: 'e06e6a91-e965-4ab6-a4bf-8c5c581cb183',
          placement: [null, '1140667'],
          metricsTag: null
        },
        'insider-2': {
          identifier: 'c94c5c41-74ef-4d2e-b2ef-7ec5b552c2db',
          placement: [null, '1140677'],
          metricsTag: null
        },
        'article-inside': {
          identifier: '72bfa528-ef53-4c7c-8db9-34dde14f7131',
          placement: [null, '1124945'],
          metricsTag:
            'https://s407.meetrics.net/bb-mx/prime/mtrcs_783879.js?pjid=783879&place=1124945&cpid=Dohi_publishing&site=Hockeymagasinet.se&adc=Desktop&cb=1124945&size=300x250'
        },
        'article-below': {
          identifier: 'e23d3ae0-9518-40b7-a927-10f334d33d56',
          placement: [null, '1124944'],
          metricsTag:
            'https://s407.meetrics.net/bb-mx/prime/mtrcs_783879.js?pjid=783879&place=1124944&cpid=Dohi_publishing&site=Hockeymagasinet.se&adc=Desktop&cb=1124944&size=300x250'
        }
      }
    }
  };
  private observers: MutationObserver[] = [];
  private idpt: any;

  constructor(private privacyService: PrivacyService, private router: Router) {
    this.idpt = (<any>window).idpt;
    this.idpt.que = this.idpt.que || [];

    privacyService.GDPRApproval$.subscribe(this.reLoadAds);

    /* Since ads are not automaticaly reloaded on route change, we have to manually add a listener that will reload and render
    ads on rute changes */
    router.events
      .filter(event => event instanceof NavigationEnd)
      .subscribe(() => {
        this.reLoadAds(privacyService.checkGDPR());
      });
  }

  /**
   * Load all the currently existing ads again.
   * @param GDPRIsApproved If GDPR has been approved.
   */
  private reLoadAds(GDPRIsApproved: boolean) {
    if (this.idpt && GDPRIsApproved != null) {
      this.idpt.que.push(() => {
        this.idpt.setGdprConsent(GDPRIsApproved ? 1 : 0);
        this.idpt.collapseEmptySlots();
      });

      if (this.idpt.adSlotStatuses && this.idpt.adSlotStatuses.adSlotStatuses) {
        const ads = this.idpt.adSlotStatuses.adSlotStatuses;
        Object.keys(ads).forEach((ad: String) => {
          this.idpt.que.push(() => {
            this.idpt.loadAds(ad);
            this.idpt.renderAd(ad);
          });
        });
      }
    }
  }

  /**
   * Returns the mapping of an ad with the given identifier.
   * @param adName The name of the ad.
   */
  getAddMapping(adName: string): SellbranchMappingInterface {
    return this.findMapping(adName);
  }

  private findMapping(name: string) {
    for (let desktopKey in this.mappings.desktop) {
      if (this.mappings.desktop[desktopKey][name]) {
        return this.mappings.desktop[desktopKey][name];
      }
    }
    for (let mobileKey in this.mappings.mobile) {
      if (this.mappings.mobile[mobileKey][name]) {
        return this.mappings.mobile[mobileKey][name];
      }
    }
  }

  /**
   * Loads an ad mapping by adding an observer to the ad container to check if the ad has loaded. It then adds the mapping to the idpt
   * script which handles loading of ads from the ad network. It also adds a metrics script tag with the url of the mappings metricsTag.
   *
   * If the identifier of the ad already has been used the idpt script won't allow more versions of it to load. If this is not considedred
   * it will lead to the ad only being inserted in one place, but every SellbranchAdComponenet will think that the ad is loaded.
   * Which is not the desired behavior
   * @param mapping The mapping of the add to load.
   * @param onLoadCallback The function to run when the ad loads.
   */
  loadAd(
    mapping: SellbranchMappingInterface,
    onLoadCallback: Function
  ): boolean {
    if (mapping && this.idpt) {
      this.addObserverToAdContainer(mapping, onLoadCallback);
      this.addToIdpt(mapping);
      this.addMetricsTag(mapping.identifier, mapping.identifier);

      if (this.privacyService.checkGDPR() != null) {
        this.idpt.que.push(() => {
          this.idpt.loadAds([mapping.identifier]);
          this.idpt.renderAd(mapping.identifier);
        });
      }
      return true;
    }
    console.error('Ad not loaded', mapping);
    return false;
  }

  /**
   * Adds the ad mapping to the idpt script. The idpt script is the ad networks script for loading ads.
   * @param mapping The mapping to add to the idpt script.
   */
  private addToIdpt(mapping: SellbranchMappingInterface) {
    // Wait for DOM to get built and our div accessible
    // idpt is added by the ad script when it loads. So without <any> the compiler will complain that idpt does not exist on window.

    const gdpr = this.privacyService.checkGDPR() ? 1 : 0;
    if (this.idpt) {
      this.idpt.que.push(() => {
        this.idpt.setGdprConsent(gdpr);
        this.idpt.createAdSlot(mapping.identifier).setSizeMapping(
          this.idpt
            .createSizeMapping()
            .addPlacementMapping([0, 0], mapping.placement[0])
            .addPlacementMapping([980, 0], mapping.placement[1])
        );
      });
    }
  }

  /**
   * Adds an observer to the ads container that checks if the iframe of the ad has loaded. If the iframe exists the callback function is
   * called. The observer is added to the observers array and should be removed when the ad component is destroyed.
   * @param mapping The mapping to observe the iframe of.
   * @param onLoadCallback The function to call if the iframe exists.
   */
  private addObserverToAdContainer(
    mapping: SellbranchMappingInterface,
    onLoadCallback: Function
  ) {
    let callback = mutationsList => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          let adIframe = document.getElementById(
            `improvedigital/iframe-${mapping.identifier}`
          );
          if (adIframe) {
            adIframe.addEventListener('load', () => onLoadCallback(adIframe));
          } else {
            onLoadCallback(false);
          }
        }
      }
    };

    let target = document.getElementById(mapping.identifier);
    let config = { attributes: true, childList: true };

    this.observers[mapping.identifier] = new MutationObserver(callback);
    this.observers[mapping.identifier].observe(target, config);
  }

  /**
   * This appends a script tag to the element with the target id. The src of the script is the metricsTag found in the local mappings
   * matching the given identifier. Nothing is done if the mapping doesn't exist or if it doesn't have a metrics tag.
   * @param targetID The id of the element to append the script to.
   * @param identifier The id of the mapping to get the metrics src from.
   */
  private addMetricsTag(targetID: string, identifier: string) {
    if (this.mappings[identifier] && this.mappings[identifier].metricsTag) {
      const el = document.getElementById(targetID);
      const script = document.createElement('script');
      script.async = true;
      script.src = this.mappings[identifier].metricsTag;
      el.appendChild(script);
    }
  }

  /**
   * Disconnect the observer for the ad with the given identifier. Nothing is done if no ebserver exists.
   * @param identifier The identifier of the ad.
   */
  disconnectObserver(identifier: string) {
    if (this.observers[identifier]) {
      this.observers[identifier].disconnect();
    }
  }

  getGroupKeys(group): { mobile: string[]; desktop: string[] } {
    return {
      mobile: Object.keys(this.mappings.mobile[group]),
      desktop: Object.keys(this.mappings.desktop[group])
    };
  }
}
