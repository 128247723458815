import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from '../../api.service';
import { SeoService } from '../../services';

@Component({
  selector: 'national',
  templateUrl: 'national.component.html',
  styleUrls: ['national.component.scss'],
})
export class NationalComponent implements OnInit {
  teamID: any;
  teams = [];
  team: any;
  loaded: boolean = false;

  constructor(private route: ActivatedRoute, private API: APIService, private seoService: SeoService) {
    API.GET('teams/national').subscribe(teams => {
      this.teams = teams;
      this.teams.map(team => {
        API.GET('articles', {
          limit: 5,
          tag: team.name
        }).subscribe(articles => {
          team.posts = articles;
          this.loaded = true
        })
      })
    })
  }

  enableSeoDetailsOverview () {
    this.seoService.setTitle('Landslag - Hockeymagasinet');
    this.seoService.setMetaDescription('Lista på alla landslag')
  }

  enableSeoDetailsDetailed () {
    this.seoService.setTitle(this.team.name + ' - Hockeymagasinet');
    this.seoService.setMetaDescription('Artiklar och inlägg som handlar om ' + this.team.name)
  }

  ngOnInit () {
    this.route.params.subscribe(params => {
      this.teamID = params['id'];

      if (this.teamID) {
        this.fetchNationalTeam(this.teamID)
      } else {
        this.enableSeoDetailsOverview()
      }
    })
  }

  fetchNationalTeam (id) {
    this.API.GET('teams/national').subscribe(teams => {
      this.team = teams.filter(team => team.slug === id)[0];

      if (typeof this.team === 'undefined') {
        return
      }

      this.API.GET('articles', {
        tag: this.team.name,
        limit: 9
      }).subscribe(posts => {
        this.enableSeoDetailsDetailed();
        if (this.team) {
          this.team.posts = posts;
        }
      })
    })

  }
}
