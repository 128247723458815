import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'medium'
})
export class MediumPipe implements PipeTransform {

  transform(value: string, args?: any): string {
    if(!value) {
      return value;
    }
    return this.addTagToFileNameBeforeExtension(value, '-medium');
  }

  private addTagToFileNameBeforeExtension(filename, tag) {
    return filename.replace(/(\.[\w\d_-]+)$/i, (tag + '$1'));
  }

}
