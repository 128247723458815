import { Component } from '@angular/core';

@Component({
  selector: 'spinner',
  template: `<div class="spinner"></div>`,
  styleUrls: ['spinner.component.scss']
})
export class SpinnerComponent {
  constructor() { }
}
