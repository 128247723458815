import { Component, Input, ElementRef, HostBinding, ChangeDetectionStrategy, NgZone } from '@angular/core';

import 'owl.carousel';

@Component({
  selector: 'carousel',
  template: `<ng-content></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent {
  @HostBinding('class') defaultClass = 'owl-carousel owl-theme';
  @Input() options: any;

  width:number = 0;

  $nativeElement: ElementRef;
  $owlElement: ElementRef;

  constructor(private el: ElementRef, private zone: NgZone) {

  }

  resetVideoSize () {
    if (!this.$nativeElement || this.width === $(this.$nativeElement).innerWidth()) {
      return
    }

    this.width = $(this.$nativeElement).innerWidth();

    if (!('zone' in this)) {
      return;
    }

    this.zone.runOutsideAngular(() => {
      var items = $(this.$nativeElement).find('.owl-item:not([data-video])');
      var videos = $(this.$nativeElement).find('.owl-video-wrapper');
      var v_height = 0;

      var v_width = (items.css('width') != 'auto') ? items.css('width') : items.innerWidth();

      items.each(function(){
        var h = $(this).innerHeight();
        if(h > v_height) v_height = h;
      });

      videos.css({
        height: v_height,
        width: v_width
      });

    })
  };


  ngAfterViewInit () {
    this.zone.runOutsideAngular(() => {
      this.options.onResized = this.resetVideoSize;
      this.options.onRefreshed = this.resetVideoSize;

      this.$nativeElement = this.el.nativeElement;
      this.$owlElement = (<any>$(this.el.nativeElement)).owlCarousel(this.options || {});
    })
  }

  ngAfterViewChecked () {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        this.resetVideoSize();
        $(this.$nativeElement).data('owl.carousel').onResize()

      }, 100)
    })
  }

  ngOnDestroy() {
    this.$owlElement = null;
  }
}
