<interstitial-modal
  *ngIf="showInterstitial"
  [ngClass]="{hideInterstitial: !interstitialIsLoaded}"
  (loaded)="interstitialLoaded($event)"
  #modal
></interstitial-modal>

<gdpr-alert
  [visible]="showGdprDialog"
  (onApprove)="approveGdpr()"
></gdpr-alert>

<div
  class="wrapper"
  [class.hidden]="menuService.isMenuOpen()"
>
  <section class="content">
    <header></header>
    <!-- <ad identifier='div-gpt-ad-123456789-1'></ad> -->
    <div class="wideContainer">
      <div class="container">
        <sellbranch-ad adName='panorama-top'></sellbranch-ad>
        <sellbranch-ad adName='mobil-1'></sellbranch-ad>
        <div>
          <router-outlet></router-outlet>
        </div>
        <sellbranch-ad adName='panorama-bottom'></sellbranch-ad>
        <sellbranch-ad adName='mobil-4'></sellbranch-ad>
      </div>
      <div class="widescreenAd">
        <sellbranch-ad adName='widescreen-1'></sellbranch-ad>
        <sellbranch-ad adName='widescreen-2'></sellbranch-ad>
      </div>
    </div>
    <acast></acast>
    <footer>
    </footer>
  </section>

</div>
