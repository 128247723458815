import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Http, Headers, Request, RequestOptions, RequestMethod, URLSearchParams } from '@angular/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';

@Injectable()
export class APIService {
  private BASE_URL: string;
  private ENVIRONMENT_URLS = {
    development: 'http://localhost:10030/',
    // development: 'https://backend.hockeymagasinet.com/',
    staging: 'https://backend.hockey.dohi.agency/',
    production: 'https://backend.hockeymagasinet.com/'
  };

  constructor(private http: Http) {
    this.BASE_URL = this.ENVIRONMENT_URLS[environment.env];
    console.info(`environment set to [${environment.env}]`);
    console.info(`api url set to [${this.BASE_URL}]`);
  }

  trimHTML(html) {
    html = html.replace(/&nbsp;/g, '');
    return html ? String(html).replace(/<[^>]+>/gm, '') : '';
  }


  PostTo (url, data): Observable<any> {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');

    let options = new RequestOptions({
      method: RequestMethod.Post,
      url: url,
      body: JSON.stringify(data) || '',
      headers: headers
    });

    return this.http
      .request(new Request(options))
      .map(response => response.json());
  }

  GET (endpoint, parameters = undefined): Observable<any> {
    let params: URLSearchParams = new URLSearchParams();
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    // headers.append("Authorization", this.getAuthorizationHeader())

    if (parameters) {
      Object.keys(parameters).map(key => {
        params.set(key, parameters[key]);
      });
    }

    let options = new RequestOptions({
      method: RequestMethod.Get,
      url: this.BASE_URL + endpoint,
      search: params,
      headers: headers,
      body: ''
    });

    return this.http
      .request(new Request(options))
      .map(response => response.json());
  }


  POST (endpoint, data): Observable<any> {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');

    let options = new RequestOptions({
      method: RequestMethod.Post,
      url: this.BASE_URL + endpoint,
      body: JSON.stringify(data) || '',
      headers: headers
    });

    return this.http
      .request(new Request(options))
      .map(response => response.text() ? response.json() : {});
  }

  PUT (endpoint, data): Observable<any> {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');

    let options = new RequestOptions({
      method: RequestMethod.Put,
      url: this.BASE_URL + endpoint,
      body: JSON.stringify(data) || '',
      headers: headers
    });

    return this.http
      .request(new Request(options))
      .map(response => response.text() ? response.json() : {});
  }
}
