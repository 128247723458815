<section class="menu" *ngIf="menuService.isMenuOpen()">
  <div class="top">
    <i class="hamburger fa fa-times" (click)="menuService.closeMenu()" aria-hidden="true"></i>
    <img class="logo" src="/assets/images/logo-inverted.svg" />
    <img class="camps-trigger" src="/assets/images/team_onwhite.svg" />
  </div>

  <div class="buttons">
    <push-button size="mobile" class="fullWidth"></push-button>
  </div>

  <div class="search">
    <input class="search--field" placeholder="Liga, spelare, artikel, ..." [(ngModel)]="searchTerm" />
    <button class="search--button" [routerLink]="['/search', searchTerm]">
      <span>Sök</span> <i class="fa fa-search" aria-hidden="true"></i>
    </button>
  </div>

  <section class="menu--content">
    <a [routerLink]="['/home']">Hem</a>

    <div class="expandable" [class.flipped]="!isCollapsed('LIGOR')" (click)="toggle('LIGOR')">
      Ligor
    </div>
    <div class="inner" [hidden]="isCollapsed('LIGOR')">
      <a *ngFor="let league of leagues" [routerLink]="['/leagues', league.slug]">{{ league.name }}</a>
    </div>

    <div class="expandable" [class.flipped]="!isCollapsed('LANDSLAGEN')" (click)="toggle('LANDSLAGEN')">
      Landslagen
    </div>
    <div class="inner" [hidden]="isCollapsed('LANDSLAGEN')">
      <a *ngFor="let team of nationalTeams" [routerLink]="['/national', team.slug]">{{ team.name }}</a>
    </div>

    <div class="expandable" [class.flipped]="!isCollapsed('BLOGGAR')" (click)="toggle('BLOGGAR')">
      Bloggar
    </div>
    <div class="inner" [hidden]="isCollapsed('BLOGGAR')">
      <a *ngFor="let blog of blogs" [routerLink]="['/blogs', blog.slug]">{{
        blog.owner.name
        }}</a>
    </div>

    <div class="expandable" [class.flipped]="!isCollapsed('AWARDS')" (click)="toggle('AWARDS')">
      Awards
    </div>
    <div class="inner" [hidden]="isCollapsed('AWARDS')">
      <a [routerLink]="['/awards']">Alla awards</a>
      <a [routerLink]="['/awards/player-of-the-month']">Månadens spelare</a>
    </div>

    <a [routerLink]="['/about']">Om oss</a>
    <a href="https://ocast.com/sv/brand/hockeymagasinet-com-586">Annonsera</a>
  </section>
</section>

<div class="header--desktop">
  <div class="links-header">
    <div class="container">
      <!--
        <div class="carousel">
          <header-carousel></header-carousel>
        </div>
      -->

      <div class="links">
        <a class="logo-container" [routerLink]="['/']">
          <img class="logo" src="/assets/images/logo-inverted.svg" />
        </a>

        <h2 class="link" routerLinkActive="active" [routerLink]="['/home']">
          Hem
        </h2>

        <!-- Dropdown för ligor -->
        <div class="dropdown">
          <h2 class="link" [routerLink]="['/leagues']" routerLinkActive="active">
            Ligor
          </h2>
          <ul class="dropdown-menu has-children">
            <li *ngFor="let league of leagues">
              <a [routerLink]="['/leagues', league.slug]">{{ league.name }}</a>
            </li>
          </ul>
        </div>

        <!-- Dropdown för landslagen -->
        <div class="dropdown">
          <h2 class="link" [routerLink]="['/national']" routerLinkActive="active">
            Landslagen
          </h2>
          <ul class="dropdown-menu" [class.has-children]="nationalTeams.length > 0">
            <li *ngFor="let team of nationalTeams">
              <a [routerLink]="['/national', team.slug]">{{ team.name }}</a>
            </li>
          </ul>
        </div>

        <!-- Dropdown för bloggar -->
        <div class="dropdown">
          <h2 class="link" [routerLink]="['/blogs']" routerLinkActive="active">
            Bloggar
          </h2>
          <ul class="dropdown-menu" [class.has-children]="blogs.length > 0">
            <li *ngFor="let blog of blogs">
              <a [routerLink]="['/blogs', blog.slug]">{{ blog.owner.name }}</a>
            </li>
          </ul>
        </div>

        <!-- Dropdown för Awards -->
        <div class="dropdown">
          <h2 class="link" [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active" [routerLink]="['/awards']">
            Awards
          </h2>
          <ul class="dropdown-menu" [class.has-children]="2">
            <li><a [routerLink]="['/awards']">Alla awards</a></li>
            <li><a [routerLink]="['/awards/player-of-the-month']">Månadens spelare</a></li>
          </ul>
        </div>

        <div class="dropdown">
          <h2 class="link">
            <a href="http://annons.dohi.se/">Annonsera</a>
          </h2>
          <ul class="dropdown-menu" [class.has-children]="true">
            <li>
              <a [routerLink]="['/about']" routerLinkActive="active">Om oss</a>
              <!--
                <a [routerLink]="['/blogs', blog.slug]">{{ blog.owner.name }}</a>
              -->
            </li>
            <li>
              <a [routerLink]="['/terms']" routerLinkActive="active">Användarvillkor</a>
              <!--
                <a [routerLink]="['/blogs', blog.slug]">{{ blog.owner.name }}</a>
              -->
            </li>
          </ul>
        </div>

        <h2 class="link" routerLinkActive="active" [routerLink]="['/search']">
          Sök<i class="fa fa-search" aria-hidden="true"></i>
        </h2>
      </div>
      <!-- <push-button size="desktop"></push-button> -->
    </div>
  </div>
  <div class="camps-container">
    <div class="camps-title">Guldpartners</div>
    <span class="camp" *ngFor="let camp of camps">
      <span class="info">
        <a href="{{ camp.link }}" target="_blank">
          <div class="image" [style.background-image]="'url(' + (camp.image | medium) + ')'"></div>
        </a>
      </span>
    </span>
  </div>
</div>

<div class="header--mobile sticky-nav">
  <div class="top">
    <i class="hamburger fa fa-bars" (click)="menuService.openMenu()" aria-hidden="true"></i>

    <img class="logo" [routerLink]="['/']" src="/assets/images/logo-inverted.svg" />

    <!--
      <div class="camps">
        <img class="camps-trigger" src="/assets/images/team_onwhite.svg" (click)="toggle('CAMPS')">
        <div class="camps--list" [hidden]="isCollapsed('CAMPS')">
          <div class="container">
            <img
              class="item"
              src="{{ camp.image }}"
              [style.background-image]="'url(' + (camp.image | medium) + ')'"
              (click)="goTo(camp.link)"
              *ngFor="let camp of camps">
          </div>
        </div>
      </div>
    -->
  </div>
</div>
<div class="header--mobile">
  <div class="camps-container">
    <div class="camps-title">Guldpartners</div>
    <span class="camp" *ngFor="let camp of camps">
      <span class="info">
        <a href="{{ camp.link }}" target="_blank">
          <div class="image" [style.background-image]="'url(' + (camp.image | medium) + ')'"></div>
        </a>
      </span>
    </span>
  </div>

  <div class="bottom">
    <div class="carousel">
      <header-carousel></header-carousel>
    </div>
  </div>
</div>

<!-- <ad identifier='div-gpt-ad-123456789-1'></ad> -->
