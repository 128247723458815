import { Component, Input, OnInit } from '@angular/core';
import { APIService } from '../../api.service';
import { RelatedService } from '../../services';

@Component({
  selector: 'blogs',
  templateUrl: 'blogs.component.html',
  styleUrls: ['blogs.component.scss']
})
export class BlogsComponent {
  constructor(private API: APIService, private relatedService: RelatedService) {
    relatedService.setTags(null)
  }
}
