import { Component, Input } from '@angular/core';

@Component({
  selector: 'award',
  templateUrl: './award.component.html',
  styleUrls: ['./award.component.scss']
})
export class AwardComponent {
  @Input() title: string;
  @Input() imageUrl: string;
  @Input() imageHighlightText: string;
  @Input() logoUrl: string;

  @Input() categoryText: string;
  @Input() playerName: string;
  @Input() text: string;

  constructor() {}
}
