import { Component, Input } from '@angular/core';
import { PushService } from '../../services';

@Component({
  selector: 'push-button',
  templateUrl: 'push.button.component.html',
  styleUrls: ['push.button.component.scss']
})
export class PushButtonComponent {
  @Input('size') size:string;

  constructor (public pushService: PushService) { }

  openNotificationsHelp () {
    window.open('https://support.google.com/chrome/answer/3220216?hl=sv', '_blank')
  }
}
