<div class="content">
  <div class="wrapper">
    <div class="main">
      <h4>Om oss</h4>
      <p>
        Hockeymagasinet.com drivs och ägs av Dohi Publishing sedan 2001 och är idag Sveriges största nyhetssajt för
        svensk juniorhockey.

        Hockeymagasinets ambition är att förmedla nyheter både kring landets inhemska juniorserier samt utländska i god
        journalistisk kvalité.
        Vårt huvudfokus i dag är J20 SuperElit, J18 Elit och samtliga juniorlandslag.

        Syftet med vår bevakning av juniorhockey är att dels bygga upp ett intresse men också att ge besökarna en
        möjlighet att få den bästa möjliga bevakningen av de kommande stjärnorna inom svensk och internationell
        ishockey.

        År 2015 gjorde vi en storsatsning på hemsidan och gick då från 9000 unika besökare i månaden till över 55 000
        på bara ett år.
        Vilket är ett bevis på det intresset som finns för juniorhockey idag och som växer sig allt starkare.

        Hockeymagasinet har nu nya mål i sikte och söker alltid skribenter som har ett stort engagemang och driv för
        hockey i allmänhet och gärna besitter journalistisk erfarenhet. Det för att bibehålla vår goda kvalité och fina
        rykte i hockeysverige.
      </p>

      <h4 class="staff">Hockeymagasinets Redaktion</h4>
      <div class="persons">
        <div
          class="person"
          *ngFor="let person of staff"
        >
          <div class="container">
            <div
              class="image"
              [style.background-image]="'url(' + person.image + ')'"
            ></div>
            <div>
              <h5 class="name">{{ person.name }}</h5>
              <p class="description">{{ person.description}}</p>

              <div class="social-icons">
                <a
                  *ngIf="person.email"
                  target="_blank"
                  href="mailto:{{ person.email }}"
                ><i
                    class="fa fa-envelope"
                    aria-hidden="true"
                  ></i></a>
                <a
                  *ngIf="person.twitter"
                  target="_blank"
                  href="http://twitter.com/{{ person.twitter }}"
                ><i
                    class="fa fa-twitter"
                    aria-hidden="true"
                  ></i></a>
                <a
                  *ngIf="person.phoneNumber"
                  target="_blank"
                  href="tel:{{ person.phoneNumber }}"
                ><i
                    class="fa fa-phone"
                    aria-hidden="true"
                  ></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="contact">
        <h5 class="title">Tipsa Hockeymagasinet</h5>
        <p class="about">
          Har du något rykte du vill få ut på Hockeymagasinet, eller kanske dementera? Kanske vill du bara tipsa
          redaktionen eller få kontakt med oss?

          Fyll då i formuläret nedan för att tipsa!
        </p>

        <form
          (ngSubmit)="onSubmit()"
          #form="ngForm"
        >
          <div class="form-group">
            <label for="name">Ditt namn *</label>
            <input
              [(ngModel)]="model.name"
              name="name"
              type="text"
              id="name"
              required
            >
          </div>

          <div class="form-group">
            <label for="email">Din e-post *</label>
            <input
              [(ngModel)]="model.email"
              name="email"
              type="email"
              id="email"
              required
            >
          </div>

          <div class="form-group">
            <label for="subject">Ämne</label>
            <input
              [(ngModel)]="model.subject"
              name="subject"
              type="subject"
              id="subject"
            >
          </div>

          <div class="form-group">
            <label for="text">Meddelande *</label>
            <textarea
              [(ngModel)]="model.message"
              name="message"
              type="text"
              id="message"
              required
            ></textarea>
          </div>

          <p>Fält märkta med * är obligatoriska. Vi håller dig som avsändare av tipset anonym för allmänheten om inte
            annat önskas</p>

          <div class="form-submit">
            <button
              type="submit"
              class="button"
              [disabled]="!form.form.valid"
            >Skicka in</button>
          </div>

          <span
            *ngIf="submitted"
            class="submitted"
          >Ditt tips har skickats!</span>
        </form>
      </div>
    </div>


    <div class="side">
      <hottest-articles></hottest-articles>
      <!-- <app-notice></app-notice> -->
      <sellbranch-ad adName='insider-1'></sellbranch-ad>
      <sellbranch-ad adName='mobil-2'></sellbranch-ad>
      <bloggers></bloggers>
      <!-- <app-notice></app-notice> -->
      <sellbranch-ad adName='insider-2'></sellbranch-ad>
      <sellbranch-ad adName='mobil-3'></sellbranch-ad>
      <!-- <ad identifier="div-gpt-ad-123456789-3"></ad> -->
      <!-- <ad identifier="div-gpt-ad-123456789-4"></ad> -->
    </div>
  </div>

</div>
