import { Component, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MenuService, CookieService, PrivacyService } from './services';
import { Location } from '@angular/common';

declare global {
  interface Window {
    strossle: (id: string, selector: string) => void;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  interstitialCookie: any;
  interstitialIsLoaded = false;
  isFixed = false;
  found = false;
  adTop: number;
  showGdprDialog = false;
  @ViewChild('modal') modal;
  @ViewChild('fixedad') fixedAd;

  constructor(
    private router: Router,
    public menuService: MenuService,
    private cookieService: CookieService,
    private privacyService: PrivacyService,
    private location: Location
  ) {
    this.router.events.subscribe(s => {
      if (s instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }

      this.showGdprDialog =
        this.location.path() !== '/terms' && !this.privacyService.checkGDPR();
    });

    this.showGdprDialog = this.privacyService.checkGDPR();
    this.interstitialCookie = this.cookieService.readCookie('hm_interstitial');
    initInvert();
  }

  approveGdpr = () => {
    this.privacyService.approveGDPR();
    this.showGdprDialog = false;
  };

  interstitialLoaded(hasLoaded: boolean) {
    this.interstitialIsLoaded = hasLoaded;
  }

  get showInterstitial() {
    return !this.showGdprDialog && this.interstitialCookie === null;
  }
}

function initInvert() {
  try {
    window.addEventListener('keydown', event => {
      if (
        event.altKey &&
        event.shiftKey &&
        (event.metaKey || event.ctrlKey) &&
        event.keyCode === 73
      ) {
        document.body.classList.toggle('inverted');
      }
    });
  } catch (e) {}
}
