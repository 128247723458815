<!-- <ad identifier="div-gpt-ad-123456789-2"></ad> -->
<!-- <app-notice></app-notice> -->


<div class="footer">
  <div class="footer--social">
    <div class="text text--3">
      Följ Hockeymagasinet
    </div>

    <div class="icons">
      <a target="_blank" href="https://instagram.com/hockeymagasinetcom"><i class="fa fa-instagram" aria-hidden="true"></i></a>
      <a target="_blank" href="https://facebook.com/Hockeymagasinet"><i class="fa fa-facebook" aria-hidden="true"></i></a>
      <a target="_blank" href="https://twitter.com/hockeymag"><i class="fa fa-twitter" aria-hidden="true"></i></a>
      <a target="_blank" href="https://youtube.com/user/hockeymagasinetcom"><i class="fa fa-youtube" aria-hidden="true"></i></a>
    </div>
  </div>

  <div class="footer--logo">
    <div class="logo">
      <img src="/assets/images/logo-inverted.svg">
    </div>

    <div class="text text--13">
      ©{{ currentYear }} Dohi Publishing AB. All Rights Reserved.
    </div>
  </div>

  <div class="footer--advertise html-container" #text></div>
</div>
