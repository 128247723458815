import { Injectable } from '@angular/core';
import { Router, NavigationStart, Event as NavigationEvent } from '@angular/router';

enum State {
  OPEN, CLOSED
}

@Injectable()
export class MenuService {
  menuState: State;

  constructor(router:Router) {
    router.events.forEach((event: NavigationEvent) => {
      if(event instanceof NavigationStart) {
        this.closeMenu()
      }
    });
  }

  vibrate () {
    if ('vibrate' in window.navigator) {
      window.navigator.vibrate(50)
    }
  }

  openMenu () {
    this.menuState = State.OPEN;
    this.vibrate()
  }

  closeMenu () {
    this.menuState = State.CLOSED;
    this.vibrate()
  }

  isMenuOpen () {
    return this.menuState === State.OPEN;
  }
}
