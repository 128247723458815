import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { APIService } from '../../api.service';
import { SeoService } from '../../services';

class ContactModel {
  constructor (
    public name:string,
    public email:string,
    public subject:string,
    public message:string
  ) {}
}

@Component({
  selector: 'about',
  templateUrl: 'about.component.html',
  styleUrls: ['about.component.scss']
})
export class AboutComponent {
  model:ContactModel = new ContactModel('', '', '', '');
  staff:any[];
  submitted:boolean = false;

  constructor(private API: APIService, private seoService: SeoService) {
    API.GET('users/staff').subscribe(staff => this.staff = staff);

    seoService.setTitle('Kontakt - Hockeymagasinet');
    seoService.setMetaDescription('Kom i kontakt med oss! Tipsa oss om bilder eller nyheter.')
  }

  onSubmit () {
    this.API.POST('contact', this.model).subscribe(() => this.submitted = true)
  }
}
