import {Injectable, Inject} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {Title} from '@angular/platform-browser';
//import { getDOM } from '@angular/platform-browser/src/dom/dom_adapter';
import {ɵgetDOM as getDOM} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';

@Injectable()
export class SeoService {
  private titleService: Title;
  private headElement: HTMLElement;
  private metaDescription: HTMLElement;
  private robots: HTMLElement;
  private DOM: any;

  private og_title: HTMLElement;
  private og_image: HTMLElement;
  private og_description: HTMLElement;
  private og_type: HTMLElement;
  private og_url: HTMLElement;

  constructor(titleService: Title, private router: Router, @Inject(DOCUMENT) private document: any) {
    this.titleService = titleService;
    this.DOM = getDOM();

    //this.headElement = this.DOM.query('head');
    this.headElement = this.document.head;
    this.metaDescription = this.getOrCreateMetaElement('description', 'name');
    this.robots = this.getOrCreateMetaElement('robots', 'name');

    this.og_title = this.getOrCreateMetaElement('og:title', 'property');
    this.og_image = this.getOrCreateMetaElement('og:image', 'property');
    this.og_description = this.getOrCreateMetaElement('og:description', 'property');
    this.og_type = this.getOrCreateMetaElement('og:type', 'property');
    this.og_url = this.getOrCreateMetaElement('og:url', 'property');

    this.setMetaRobots('index,follow');
    this.setType('article');
    this.setImage(window.location.origin + '/assets/images/social.png');
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
    this.og_title.setAttribute('content', newTitle);

    // Update the URL each and every time we change the title
    this.setUrl(window.location.href);
  }

  public setMetaDescription(description: string) {
    if (description && this.metaDescription && this.og_description) {
      this.metaDescription.setAttribute('content', description);
      this.og_description.setAttribute('content', description);
    }
  }

  public setImage(imageUrl) {
    this.og_image.setAttribute('content', imageUrl);
  }

  public setType(type) {
    if (type) {
      this.og_type.setAttribute('content', type);
    }
  }

  public setUrl(url) {
    this.og_url.setAttribute('content', url);
  }

  public setMetaRobots(robots: string) {
    if (robots && this.robots) {
      this.robots.setAttribute('content', robots);
    }
  }

  private getOrCreateMetaElement(name: string, attribute = 'name'): HTMLElement {
    let el: HTMLElement;
    // el = this.DOM.query('meta[property="' + name + '"]');
    el = this.document.querySelector('meta[' + attribute + '="' + name + '"]');
    if (el === null) {
      el = this.DOM.createElement('meta');
      el.setAttribute(attribute, name);
      this.headElement.appendChild(el);
    }
    return el;
  }
}
