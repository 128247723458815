import { Component, Input } from '@angular/core';
import { APIService } from '../../api.service';

@Component({
  selector: 'article-carousel',
  templateUrl: 'article.carousel.component.html',
  styleUrls: ['article.carousel.component.scss'],
})
export class ArticleCarouselComponent {
  articles: [any];

  constructor(private API: APIService) {
    this.API.GET('articles/featured', {
      limit: 5
    }).subscribe(articles => {
      this.articles = articles
    });
  }

  getValidArticles () {
    if (!this.articles) return [];

    // Only requirement is that the Article needs at least one image
    let validArticles = this.articles.filter(article => {
      return <boolean>article.images.length || false
    });

    return validArticles
  }

  getTextOverview (text) {
    text = this.API.trimHTML(text);

    let regex = /^((?:\S+\s+){20}\S+).*/;

    let result = regex.exec(text);
    if (result !== null) {
      return result[1]
    } else {
      return text;
    }
  }
}
