import {AfterViewInit, Directive, ElementRef, HostListener, Input, NgZone, OnDestroy, OnInit} from '@angular/core';

@Directive({
  selector: '[sticky]'
})
export class StickyDirective implements AfterViewInit, OnInit, OnDestroy {
  @Input() isFixable: boolean;
  @Input() isReleasable: boolean;
  @Input() stickyTime: number;

  isFixed = false;
  isReleased = false;
  header: Element;
  stoppingElement: Element;

  constructor(private element: ElementRef) {
  }

  ngOnInit() {
    if (this.isFixable) {
      // Use window.addEventListener instead of @HostListener since host listener is always added. Even if the component isn't fixable
      window.addEventListener('scroll', this.scroll);
    }
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll);
  }

  scroll = (): void => {
    this.handleScroll();
  };

  ngAfterViewInit() {
    this.header = document.getElementsByClassName('links-header').item(0);
    this.stoppingElement = document.getElementsByClassName('footer').item(0);
    if (!this.header || !this.stoppingElement) {
      console.warn('Header or stoppingElement could not be found. Please check that the class hasn\'t changed');
      this.isFixed = false;
      this.isReleased = true;
    }
  }

  private handleScroll() {

    if (this.isFixable && !this.isReleased) {
      const container = this.element.nativeElement;
      this.handleSticky(container);
    }
  }

  /**
   * Handle ads that are sticky. Ads that are sticky but not releasable will only follow for a few seconds.
   * Ads that are sticky and releasable will follow all the time.
   * @param container
   */
  private handleSticky(container) {
    const containerRect = container.getBoundingClientRect();
    const stopPositionOffset = this.getStopPosition() - (containerRect.bottom + window.scrollY);

    const startingPositionOffset = container.parentElement.getBoundingClientRect().top - containerRect.top;
    const headerBottom = this.header.getBoundingClientRect().bottom;
    const headerOffset = containerRect.top - headerBottom;

    if (stopPositionOffset <= 0 && headerOffset <= 0) {
      this.setBottomedOut(container)
    } else if ((stopPositionOffset <= 0 && headerOffset >= 0)
      || (headerOffset <= 0 && startingPositionOffset <= 0)) {
      this.handleReleasable(container);
      this.toggleFixed(true, container, headerBottom)
    } else if (startingPositionOffset >= 0) {
      this.toggleFixed(false, container, null)
    }


  }

  private getStopPosition() {
    // The subtraction at the end is how many pixels above the element the sticky should stop.
    return this.stoppingElement.getBoundingClientRect().top + window.scrollY - 70;
  }

  private handleReleasable(container) {
    const self = this;
    if (this.isReleasable) {
      setTimeout(() => {
        this.toggleFixed(false, container, null);
        self.isReleased = true;
      }, this.stickyTime);
    }
  }

  private setBottomedOut(container) {
    this.isFixed = false;
    container.style.top = this.getStopPosition() - container.clientHeight + 'px';
    container.style.position = 'absolute';
  }

  private toggleFixed(isFixed, container, top) {
    if (isFixed) {
      this.isFixed = true;
      container.style.top = top + 'px';
      container.style.position = 'fixed';
    } else {
      this.isFixed = false;
      container.style.top = 'initial';
      container.style.position = 'static';
    }
  }
}
