import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from '../../api.service';
import { SeoService } from '../../services';

@Component({
  selector: 'blog-list',
  templateUrl: 'blog.list.component.html',
  styleUrls: ['blog.list.component.scss']
})
export class BlogListComponent {
  blogs: any;

  constructor(private route: ActivatedRoute, private API: APIService, private seoService: SeoService) {
    seoService.setTitle('Bloggar - Hockeymagasinet');
    seoService.setMetaDescription('Här ser du Hockeymagasinets alla bloggare');

    this.API.GET('blogs').subscribe(blogs => this.blogs = blogs)
  }
}
