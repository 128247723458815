import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from '../../api.service';
import { SeoService } from '../../services';

@Component({
  selector: 'blog-overview',
  templateUrl: 'blog.overview.component.html',
  styleUrls: ['blog.overview.component.scss']
})
export class BlogOverviewComponent implements OnInit {
  blogs: [any];
  blog: any;
  slug: string;

  constructor(private route: ActivatedRoute, private API: APIService, private seoService: SeoService) {

  }

  ngOnInit () {
    this.route.params.subscribe(params => {
      this.slug = params['blog'];

      this.API.GET('blogs').subscribe(blogs => {
        this.blogs = blogs;
        this.fetchBlog(this.slug)
      })
    })
  }

  setSeoDetails (blog) {
    this.seoService.setTitle(blog.title + ' - Hockeymagasinet');
    this.seoService.setMetaDescription(this.API.trimHTML(blog.description));
    this.seoService.setImage(blog.owner.image)
  }

  fetchBlog (slug) {
    this.API.GET('blogs/posts', {
      blogSlug: slug
    }).subscribe(posts => {
      this.blog = this.blogs.filter(blog => blog.slug === slug)[0];
      this.blog.posts = posts;

      this.setSeoDetails(this.blog)
    })
  }

  getTextOverview (text) {
    text = this.API.trimHTML(text);

    return text.split(' ').splice(0, 50).join(' ')
  }
}
