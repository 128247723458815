<div class="content">
  <div class="wrapper">
    <div class="main">
      <h4 *ngIf="!loaded">Laddar...</h4>

      <div *ngIf="!leagueID">
        <division-news
          *ngFor="let league of leagues"
          [league]="league"
        ></division-news>
      </div>

      <div *ngIf="leagueID && league">
        <division-news
          [league]="league"
          expandable="true"
        ></division-news>
      </div>
    </div>

    <div class="side">
      <hottest-articles></hottest-articles>
      <!-- <app-notice></app-notice> -->
      <sellbranch-ad adName='insider-1'></sellbranch-ad>
      <sellbranch-ad adName='mobil-2'></sellbranch-ad>
      <!-- <ad identifier="div-gpt-ad-123456789-3"></ad> -->
      <bloggers></bloggers>
      <!-- <app-notice></app-notice> -->
      <sellbranch-ad adName='insider-2'></sellbranch-ad>
      <sellbranch-ad adName='mobil-3'></sellbranch-ad>
      <!-- <ad identifier="div-gpt-ad-123456789-4"></ad> -->
    </div>
  </div>
</div>
