<h2>
  Behandling av personuppgifter
</h2>
<p>
  I samband med att en användare lämnar personuppgifter, till exempel vid registrering, prenumeration eller köp av en
  produkt, ska användaren få information om personuppgiftsbehandlingen, och ge sitt samtycke till denna.
  Dohi Publishing olika varumärken behandlar kunders och användares personuppgifter gemensamt inom företaget.
</p>
<p>
  Dohi Publishing AB håller ett register över koncernens kunder och användare som har registrerat sig eller köpt något
  hos Dohi Publishing. Registret innehåller personuppgifter som namn, personnummer, postadress, telefonnummer,
  elektroniska adresser, leverans- och betalnings- och inköpsuppgifter, profilering baserad på adress, samt uppgifter
  baserade på användning av digitala tjänster.
</p>
<p>
  Uppgifterna används av Dohi Publishing för fakturering, information och leverans av produkter, samt marknadsföring
  och som underlag för statistik och produktutveckling. Uppgifterna kan ligga till grund för att Dohi Publishing, och i
  förekommande fall våra samarbetspartners, ska kunna anpassa innehåll, annonser och erbjudanden.
</p>
<p>
  Uppgifterna analyseras och grupperas inför urval, prioritering och planering av kontakter med kunden/användaren. Till
  uppgifterna kopplas en eller flera markörer om vilken typ av anpassning av webbtjänster och marknadskommunikation som
  riktas mot användaren, så kallad profilering.
</p>
<p>
  Dohi Publishings kunder och registrerade användare samtycker till att marknadsföring kan ske via post, telefon, eller
  e-post och sms samt andra digitala kanaler. Marknadsföring via e-post och sms regleras i marknadsföringslagen.
</p>
<p>
  Personuppgifter kan lämnas ut till Dohis samarbetspartners. Personuppgifter lämnas ut till myndighet endast där så
  krävs med stöd av lag eller myndighetsbeslut.
</p>
<p>
  Alla användare har rätt att utan kostnad återkalla samtycke, begära rättelse/radering av eventuellt felaktiga
  uppgifter eller skriftligen begära ett registerutdrag. Registerutdrag kan också begäras från respektive varumärkes
  leveransregister som Dohi Publishing ansvar och driver och där detaljerade uppgifter finns om din
  prenumeration/medlemskap. Där kan man också spärra uppgifterna mot marknadsföring.
</p>
<p>
  Personuppgifter sparas under den tid man har en pågående användarrelation och en tid därefter, eller i enlighet med
  lämnat samtycke. Samtycket är giltigt till dess användaren själv säger upp det, och upphör inte om tjänsten inte
  nyttjas och samtycket är heller inte beroende av eventuella köp av produkter.
</p>

<h3>
  Om cookies
</h3>
<p>
  Dohi Publishing använder så kallade cookies och andra liknande tekniker för att se hur Dohis digitala tjänster
  används och för att anpassa användarupplevelsen.
</p>
<p>
  Cookies består av små textfiler och är ett mycket vanligt hjälpmedel på internet. Cookie-filerna läses och lagras av
  webbläsaren i den utrustning som används för att besöka webbplatserna.  Dohi Publishings kunder och registrerade
  användare samtycker till användningen av cookies.
</p>
<p>
  Användaren kan ställa in sin webbläsare att blockera cookies och kan ta bort redan lagrade cookies. Detta kan i vissa
  fall försämra funktionaliteten och upplevelsen av webbplatsen.
</p>

<h3>
  Mer information om datalagring på Hockeymagasinet
</h3>
<h4>
  Kommentarer
</h4>
<p>
  När besökare lämnar kommentarer på webbplatsen samlar vi in de uppgifter som visas i kommentarsformuläret samt
  besökarens ip-adress och user agent-sträng som hjälp för detektering av skräpmeddelanden.
</p>
<p>
  En anonymiserad sträng som skapats utifrån din e-postadress (även kallat hash-värde) kan komma att sändas till
  tjänsten Gravatar för att avgöra om du finns registrerad där. Integritetspolicyn för tjänsten Gravatar finns på
  https://automattic.com/privacy/. När din kommentar har godkänts kommer din profilbild att visas publikt tillsammans
  med din kommentar.
</p>

<h4>
  Media
</h4>
<p>
  Om du laddar upp bilder till webbplatsen bör du undvika att ladda upp bilder där EXIF-data inkluderar data från
  GPS-lokalisering. Besökare till webbplatsen kan ladda ned och ta fram alla positioneringsuppgifter från bilder på
  webbplatsen.
</p>

<h4>
  Cookie-filer
</h4>
<p>
  Om du lämnar en kommentar på vår webbplats kan du välja att spara ditt namn, din e-postadress och webbplatsadress i
  cookie-filer. Detta är för din bekvämlighet för att du inte ska behöva fylla i dessa uppgifter igen nästa gång du
  skriver en kommentar. Dessa cookie-filer gäller i ett år.
</p>
<p>
  Om du har ett konto och loggar in på denna webbplats kommer vi att skapa en tillfällig cookie-fil för att kontrollera
  om din webbläsare accepterar cookie-filer. Denna cookie-fil innehåller ingen personligt identifierbar information och
  försvinner när du stänger din webbläsare.
</p>
<p>
  När du loggar in kommer vi dessutom att skapa flera cookie-filer för att spara information om din inloggning och dina
  val för utformning av skärmlayouten. Cookie-filer för inloggning gäller i två dagar och cookie-filer för layoutval
  gäller i ett år. Om du kryssar i ”Kom ihåg mig” kommer din cookie att finnas kvar i två veckor. Om du loggar ut från
  ditt konto kommer cookie-filerna för inloggning att tas bort.
</p>
<p>
  Om du redigerar eller publicerar en artikel kommer en extra cookie-fil att sparas i din webbläsare. Denna cookie-fil
  innehåller inga personuppgifter utan anger endast inläggs-ID för den artikel du just redigerade. Den gäller under 1
  dygn.
</p>

<h4>
  Inbäddad innehåll från andra webbplatser
</h4>
<p>
  Artiklar på denna webbplats kan innehålla inbäddat innehåll (exempelvis videoklipp, bilder, artiklar o.s.v.).
  Inbäddat innehåll från andra webbplatser beter sig precis på samma sätt som om besökaren har besökt den andra
  webbplatsen.
</p>
<p>
  Dessa webbplatser kan samla in uppgifter om dig, använda cookie-filer, bädda in ytterligare spårning från tredje part
  och övervaka din interaktion med sagda inbäddade innehåll, inklusive spårning av din interaktion med detta inbäddade
  innehåll om du har ett konto och är inloggad på webbplatsen i fråga.
</p>

<h3>
  Hur länge vi behåller era uppgifter
</h3>
<p>
  Om du skriver en kommentar kommer kommentaren och dess metadata att sparas utan tidsgräns. Anledningen till detta är
  att vi behöver kunna hitta och godkänna uppföljningskommentarer automatiskt och inte lägga dem i kö för granskning.
</p>
<p>
  För användare som registrerar sig på er webbplats  sparar vi även de personuppgifter de anger i sin användarprofil.
  Alla användare kan se, redigera eller radera sina personuppgifter när som helst (med undantaget att de inte kan ändra
  sitt användarnamn). Även webbplatsens administratörer kan se och redigera denna information.
</p>
<h3>
  Vilka rättigheter du har över dina data
</h3>
<p>
  Om du har ett konto eller har skrivit några kommentarer på denna webbplats kan du begära en exportfil med de
  personuppgifter vi har om dig, inklusive alla uppgifter du har gett oss. Du kan också begära att vi tar bort alla
  personuppgifter vi har om dig. Detta omfattar inte eventuella uppgifter som vi är tvungna att spara av
  administrativa, legala eller säkerhetsändamål.
</p>
<!--
  <p>
  Godkänn eller avvisa genom att välja nedan.
</p>
<div class="button-container">
  <button (click)="approve()">
    Jag samtycker
  </button>
  <button (click)="disapprove()">
    Jag samtycker inte
  </button>
</div>
-->
