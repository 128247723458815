import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from '../../api.service';
import { SeoService } from '../../services';

@Component({
  selector: 'home',
  templateUrl: 'leagues.component.html',
  styleUrls: ['leagues.component.scss'],
})
export class LeaguesComponent implements OnInit {
  leagueID: any;
  leagues = [];
  league: any;
  loaded: boolean = false;

  constructor(private route: ActivatedRoute, private API: APIService, private seoService: SeoService) {
    API.GET('leagues').subscribe(leagues => {
      this.leagues = leagues;
      this.leagues.map(league => {
        API.GET('articles', {
          limit: 5,
          tag: league.name
        }).subscribe(articles => {
          league.posts = articles;
          this.loaded = true;
        })
      })
    })
  }

  enableSeoDetailsOverview () {
    this.seoService.setTitle('Ligor - Hockeymagasinet');
    this.seoService.setMetaDescription('Lista på alla ligor')
  }

  enableSeoDetailsDetailed () {
    this.seoService.setTitle(this.league.name + ' - Hockeymagasinet');
    this.seoService.setMetaDescription('Artiklar och inlägg som handlar om ' + this.league.name)
  }

  ngOnInit () {
    this.route.params.subscribe(params => {
      this.leagueID = params['id'];


      if (this.leagueID) {
        this.fetchLeague(this.leagueID);
      } else {
        this.enableSeoDetailsOverview()
      }
    })
  }

  fetchLeague (id) {
    this.API.GET('leagues').subscribe(leagues => {
      this.league = leagues.filter(league => league.slug === id)[0];

      if (typeof this.league === 'undefined') {
        return
      }


      this.API.GET('articles', {
        tag: this.league.name,
        limit: 9
      }).subscribe(posts => {
        this.enableSeoDetailsDetailed();

        if (this.league) {
          this.league.posts = posts;
        }
      })
    })

  }
}
