<div class="push-notifications--{{ size }}" *ngIf="pushService.isEligibleForNotifications()">
  <div *ngIf="pushService.hasNotificationPermissions()">
    <button
    class="enable"
    [disabled]="pushService.isPushLoading"
    *ngIf="!pushService.isPushEnabled" (click)="pushService.registerServices()">
      Slå på push-notiser
      <i class="fa fa-bell" aria-hidden="true"></i>
    </button>

    <button
    [disabled]="pushService.isPushLoading"
    *ngIf="pushService.isPushEnabled" (click)="pushService.disableServices()">
      Stäng av push-notiser
      <i class="fa fa-bell" aria-hidden="true"></i>
    </button>
  </div>

  <button
  class="denied"
  *ngIf="!pushService.isPushEnabled && !pushService.hasNotificationPermissions()"
  (click)="openNotificationsHelp()">
    Push-notiser avslagna
    <i class="fa fa-bell" aria-hidden="true"></i>
  </button>
</div>
