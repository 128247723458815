<div class="content">
  <div class="wrapper">
    <div class="main">
      <h4 *ngIf="!loaded">Laddar...</h4>

      <div *ngIf="!teamID">
        <division-news
          *ngFor="let team of teams"
          [national]="team"
        ></division-news>
      </div>

      <div *ngIf="teamID && team">
        <division-news
          [national]="team"
          expandable="true"
        ></division-news>
      </div>
    </div>

    <div class="side">
      <hottest-articles></hottest-articles>
      <!-- <app-notice></app-notice> -->
      <sellbranch-ad adName='insider-1'></sellbranch-ad>
      <sellbranch-ad adName='mobil-2'></sellbranch-ad>
      <!-- <ad identifier="div-gpt-ad-123456789-3"></ad> -->
      <bloggers></bloggers>
      <!-- <app-notice></app-notice> -->
      <sellbranch-ad adName='insider-2'></sellbranch-ad>
      <sellbranch-ad adName='mobil-3'></sellbranch-ad>
      <!-- <ad identifier="div-gpt-ad-123456789-4"></ad> -->
    </div>
  </div>

</div>
