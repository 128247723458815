<div
  class="potm-list"
  #anchor
>
  <h4>Månadens spelare</h4>

  <p>
    Hockeymagasinet valde år 2015 att återinföra utmärkelsen månadens juniorspelare som en del av Hockeymagasinet
    Awards. Det är en utmärkelse som Hockemagasinets redaktion tilldelade endast en spelare i J20 SuperElit men som nu
    inkluderar en spelare från landets samtliga högsta juniordivisioner och högsta seniorserier.
  </p>

  <div *ngIf="availableMonths.length > 0">
    Månad &nbsp;
    <select (change)="onChangeMonth($event.target.value)">
      <option
        *ngFor="let month of availableMonths"
        [value]="month.index"
        [disabled]="month.disabled"
        [selected]="month.index === filteredMonth"
      >{{month.text}}</option>
    </select>
  </div>

  <em *ngIf="potmIsEmpty">
    Det finns inga månadens spelare än
  </em>

  <div *ngFor="let pomt of filteredPlayersOfTheMonth">
    <award
      [title]="pomt.league.title"
      [imageUrl]="pomt.player.image"
      [imageHighlightText]="getHighlightDate(pomt.date)"
      [categoryText]="'Månadens spelare'"
      [playerName]="pomt.player.name"
      [text]="pomt.motivation"
    ></award>
  </div>

  <div
    class="year-picker"
    *ngIf="showYearList"
  >
    <div
      class="year"
      *ngFor="let year of yearList"
      [class.active]="year.year === filteredYear"
      (click)="onChangeYear(year.year)"
      [style.background-image]="'url(' + year.image + ')'"
    >
      <div class="text">{{year.year}}</div>
    </div>
  </div>
