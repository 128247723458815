import { Component } from '@angular/core';
import { APIService } from '../../api.service';
import { MenuService } from '../../services';

@Component({
  selector: 'header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss']
})
export class HeaderComponent {
  toggles = [];
  leagues = [];
  camps = [];
  blogs = [];
  nationalTeams = [];

  constructor(private API: APIService, public menuService: MenuService) {
    API.GET('leagues').subscribe(leagues => (this.leagues = leagues));
    API.GET('blogs').subscribe(blogs => (this.blogs = blogs));
    API.GET('camps').subscribe(camps => (this.camps = camps));
    API.GET('teams/national').subscribe(
      nationalTeams => (this.nationalTeams = nationalTeams)
    );
  }

  openNotificationsHelp() {
    window.open(
      'https://support.google.com/chrome/answer/3220216?hl=sv',
      '_blank'
    );
  }

  isCollapsed(hash) {
    return !this.toggles[hash];
  }

  goTo(link) {
    window.open(link, '_blank');
  }

  toggle(hash) {
    if (this.toggles[hash]) {
      this.toggles[hash] = false;
    } else {
      this.toggles = []; // collapse all
      this.toggles[hash] = true;
    }
  }
}
