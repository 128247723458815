export * from './home';
export * from './article';
export * from './leagues';
export * from './blogs';
export * from './awards';
export * from './search';
export * from './national';
export * from './about';
export * from './special';
export * from './terms';
export * from './player-of-the-month';
