<h4 [routerLink]="['/blogs', post?.blogSlug]">&lt; Tillbaka</h4>
<spinner *ngIf="!post"></spinner>

<div class="post">
  <div class="carousel">
    <div class="inner" *ngIf="post">
      <!--<carousel *ngIf="carouselActive" [options]="{items: 1, video: true, lazyLoad: false, loop: true,  margin: 10, stagePadding: 50}">-->
        <div class="item" *ngFor="let image of post?.images">
          <img src="{{ image.url }}" />
          <div class="details">
            <span *ngIf="image.caption.length">{{ image.caption }}</span>
            <span *ngIf="image.photographer.length">Fotograf: {{ image.photographer }}</span>
          </div>
        </div>
        <!--<a class="owl-video" *ngFor="let video of getVideos()" href="{{ video }}"></a>
      </carousel>-->
    </div>
  </div>

  <h5 class="title">{{ post?.title }}</h5>
  <timestamp [time]="post?.published" [friendly]="false"></timestamp>
  <div class="text" [innerHTML]="bypassSanitize(post?.text)"></div>

  <div class="tags">
    <tag *ngFor="let tag of post?.tags" [text]="tag"></tag>
  </div>

  <author [data]="post?.author"></author>

</div>

<!-- <app-notice></app-notice> -->
<sellbranch-ad adName='article-below'></sellbranch-ad>
<sellbranch-ad adName='mobil-6'></sellbranch-ad>
<!-- <ad identifier="div-gpt-ad-123456789-5"></ad> -->
<!-- <div *ngIf="post?.commentsEnabled" id="div-gpt-ad-123456789-5"></div>
<div *ngIf="post?.commentsEnabled" id="disqus_thread"></div>
<noscript *ngIf="post?.commentsEnabled">Please enable JavaScript to view the <a href="https://disqus.com/?ref_noscript">comments powered by Disqus.</a></noscript> -->
