import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PrivacyService } from '../../services';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit, OnDestroy {
  visible = true;

  constructor(private privacyService: PrivacyService, private router: Router) {}

  ngOnInit() {
    this.privacyService.loadTerms();
  }

  approve() {
    this.privacyService.approveGDPR();
    window.location.href = '/home';
  }

  disapprove() {
    this.privacyService.disapproveGDPR();
    window.location.href = '/home';
  }

  ngOnDestroy() {
    this.privacyService.destroyTerms();
  }
}
