import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { APIService } from '../../api.service';

@Component({
  selector: 'blog-inline',
  templateUrl: 'blog.inline.component.html',
  styleUrls: ['blog.inline.component.scss']
})
export class BlogInlineComponent {
  @Input() blog: any;
  @Input() shouldLink: any;
  trustedUrl: SafeUrl;

  constructor (private API: APIService, private sanitizer: DomSanitizer) {

  }

  bypassSanitize(html: string) {
    this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(html);
    return this.trustedUrl;
  }
}
