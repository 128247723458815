<div class="modal">
  <div class="modal-backdrop"></div>

  <div
    class="modal-container"
    (click)="onHide.emit();"
  >
    <div
      class="modal-content"
      (click)="stopPropagation($event)"
    >
      <div class="modal-header">
        <h3>
          <ng-content select="[modal-header]"></ng-content>
        </h3>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
      <div class="modal-footer">
        <ng-content
          class="modal-footer__content"
          select="[modal-footer]"
        ></ng-content>
      </div>
    </div>
  </div>
</div>
