import {
  Component,
  Output,
  EventEmitter,
  Renderer2,
  OnDestroy
} from '@angular/core';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnDestroy {
  @Output() onHide: EventEmitter<any> = new EventEmitter();

  constructor(private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'modal-open');
  }

  public stopPropagation = (event: MouseEvent) => {
    event.stopPropagation();
  };

  public ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
  }
}
