<div class="award">
  <h5 class="title">{{title}}</h5>
  <div
    class="picture"
    [style.background-image]="'url(' + imageUrl + ')'"
  >
    <highlight [highlight]="{'text': imageHighlightText, 'theme': 'award', 'display': true}"></highlight>
    <img
      *ngIf="logoUrl"
      class="logo"
      [src]="logoUrl"
    />

  </div>

  <div class="category">{{categoryText}}</div>
  <h5 class="title">{{playerName}}</h5>
  <p
    class="text"
    [innerHTML]="text"
  ></p>
</div>
