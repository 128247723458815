import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ElementRef,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import { AdService, PrivacyService } from '../../services';
import { SellbranchMappingInterface } from '../../interfaces/sellbranch-mapping-interface';

@Component({
  selector: 'sellbranch-ad',
  templateUrl: './sellbranch-ad.component.html',
  styleUrls: ['./sellbranch-ad.component.scss']
})
export class SellbranchAdComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() interstitialLoaded: EventEmitter<boolean> = new EventEmitter();
  @Input() adName: string;
  @ViewChild('ad')
  private adWrapper: ElementRef;
  termsAreLoaded = false;
  placeholderDimensions: {};
  isLoaded = false;
  isInterstitial = false;
  mapping: SellbranchMappingInterface;
  adWasCreated = true;

  constructor(
    private privacyService: PrivacyService,
    private adService: AdService,
    private cdRef: ChangeDetectorRef
  ) {
    this.placeholderDimensions = {
      'height.px': 0,
      'width.px': 0
    };
  }

  /**
   * Get the mappings of the ad and check if the privacy terms are accepted.
   */
  ngOnInit() {
    this.mapping = this.adService.getAddMapping(this.adName);
    if (!this.mapping) {
      this.mapping = {
        identifier: null,
        placement: [null, null],
        fixable: false,
        metricsTag: null
      };
      console.error('No ad found with the ad name: ', this.adName);
    }
    this.privacyService.termsLoadedSubject.subscribe(d => {
      this.termsAreLoaded = d;
    });
  }

  /**
   * Check if the ad contains an actual ad or if the ad is empty. If the ad is empty is loaded will be false. the placeholders dimensions
   * are updated independent of what state the ad is in.
   * @param adIframe
   */
  onLoad = (adIframe: Element) => {
    this.isLoaded =
      adIframe && adIframe.clientWidth > 0 && adIframe.clientHeight > 40;

    if (this.isLoaded) {
      this.isInterstitial =
        this.adName === 'interstitial-desktop' ||
        this.adName === 'interstitial-mobil';

      this.interstitialLoaded.emit(this.isLoaded);
    }

    // Always update the placeholder dimensions to handle for instance
    // loaded ads size when the screen is resized
    setTimeout(() => {
      this.updatePlaceholderDimensions(
        this.adWrapper.nativeElement.clientHeight,
        this.adWrapper.nativeElement.clientWidth
      );
    }, 1);
  };

  /**
   * The ad has to be loaded after the view is initialized since it will modify the DOM. Which can't be done if there is no DOM to modify.
   */
  ngAfterViewInit() {
    if (this.mapping.identifier) {
      let adWasCreated = this.adService.loadAd(this.mapping, this.onLoad);

      // This check is to avoid ExpressionChangedAfterItHasBeenCheckedError in the console
      if (this.adWasCreated !== adWasCreated) {
        this.adWasCreated = adWasCreated;
        this.cdRef.detectChanges();
      }
    }
  }

  /**
   * If the component is destroyed the observer for the ad should be removed.
   */
  ngOnDestroy() {
    this.adService.disconnectObserver(this.mapping.identifier);
  }

  /**
   * Updates the placeholders width and height to be the same as the ad. This is to ensure that the page does not jump when the ad is fixed.
   */
  updatePlaceholderDimensions(height: number, width: number) {
    this.placeholderDimensions = {
      'height.px': height,
      'width.px': width
    };
  }
}
