<div class="content">

  <div
    class="latest-articles"
    *ngIf="articles.length"
  >
    <h3>JUST NU</h3>
    <div class="articles-container">
      <div
        class="article"
        *ngFor="let post of latestArticles"
        [routerLink]="['/article', post.slug]"
      >
        <div
          class="picture"
          *ngIf="post?.images"
          [style.background-image]="'url(' + post.images[0]?.url + ')'"
        >
        </div>
        <span class="text">{{post.title}}</span>
      </div>
    </div>
  </div>

  <div class="wrapper">
    <div class="main">
      <h4>Hem</h4>
      <article-carousel></article-carousel>

      <div
        class="posts"
        *ngIf="articles.length"
      >
        <ng-container *ngFor="let post of articles;let i = index">
          <ng-container *ngIf="i%articlesBetweenAds=== 0">
            <sellbranch-ad [adName]="getNextAd(i).mobile"></sellbranch-ad>
            <sellbranch-ad [adName]="getNextAd(i).desktop"></sellbranch-ad>
          </ng-container>
          <post-inline [post]="post"></post-inline>
        </ng-container>
      </div>
      <div
        class="load-more"
        infiniteScroll
        (elementReached)="fetchMoreArticles()"
        [class.fetching]="fetching"
      >
        Laddar...
      </div>
    </div>

    <div class="side">
      <hottest-articles></hottest-articles>
      <sellbranch-ad adName='insider-1'></sellbranch-ad>
      <sellbranch-ad adName='mobil-2'></sellbranch-ad>
      <bloggers></bloggers>
      <sellbranch-ad adName='insider-2'></sellbranch-ad>
      <sellbranch-ad adName='mobil-3'></sellbranch-ad>
    </div>
  </div>

</div>
