<h4 *ngIf="league?.posts?.length"   [routerLink]="['/leagues', league?.slug]">{{ league.name }}</h4>
<h4 *ngIf="national?.posts?.length" [routerLink]="['/national', national?.slug]" >{{ national.name }}</h4>

<div class="post" *ngIf="league?.posts?.length">
  <card size="large" [post]="league.posts[0]"></card>
  <div class="row">
    <card size="medium" *ngFor="let post of getItems(); let i = index" [post]="post"></card>
  </div>

  <div class="show-more" *ngIf="hasMoreItems">
    <a *ngIf="!expandable" class="text--6" [routerLink]="['/leagues', league.slug]">Visa fler artiklar</a>
    <a *ngIf="expandable"  class="text--6" (click)="loadMoreArticles(league.posts)">Visa fler artiklar</a>
  </div>
</div>

<div class="post" *ngIf="national?.posts?.length">
  <card size="large" [post]="national.posts[0]"></card>
  <div class="row">
    <card size="medium" *ngFor="let post of getItems(); let i = index" [post]="post"></card>
  </div>

  <div class="show-more" *ngIf="hasMoreItems">
    <a *ngIf="!expandable" class="text--6" [routerLink]="['/national', national.slug]">Visa fler artiklar</a>
    <a *ngIf="expandable"  class="text--6" (click)="loadMoreArticles(national.posts)">Visa fler artiklar</a>
  </div>
</div>
