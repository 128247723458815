import {
  Component,
  AfterViewInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Router } from '@angular/router';
import { PrivacyService, CookieService } from '../../services';

@Component({
  selector: 'gdpr-alert',
  templateUrl: './gdpr-alert.component.html',
  styleUrls: ['./gdpr-alert.component.scss']
})
export class GdprAlertComponent {
  @Input() visible: boolean;
  @Output() onApprove: EventEmitter<void> = new EventEmitter();
  @Output() onBackdropClick: EventEmitter<void> = new EventEmitter();
}
