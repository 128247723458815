
<div class="hottest" *ngIf="articles?.length !== 0">
  <h4>Hetast i veckan</h4>
  <spinner *ngIf="!articles"></spinner>

  <div *ngFor="let article of articles">
    <div class="article" *ngIf="article?.title?.length && article?.images.length">
      <div class="picture" [routerLink]="['/article', article.slug]" [style.background-image]="'url(' + (article.images[0].url | medium) + ')'"></div>
      <span class="title" [routerLink]="['/article', article.slug]">{{ article.title }}</span>
    </div>
  </div>
</div>
