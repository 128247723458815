import { Component } from '@angular/core';

@Component({
  selector: 'app-player-of-the-month',
  templateUrl: './player-of-the-month.component.html',
  styleUrls: ['./player-of-the-month.component.scss']
})
export class PlayerOfTheMonthComponent {
  constructor() {}
}
