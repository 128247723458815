import { Component, Input } from '@angular/core';

@Component({
  selector: 'chart',
  templateUrl: 'chart.component.html',
  styleUrls: ['chart.component.scss']
})
export class ChartComponent {
  @Input() data: any;

  constructor() { }
}
