import {OnInit, Component} from '@angular/core';
import {AcastService} from '../../services';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'acast',
  templateUrl: './acast.component.html',
  styleUrls: ['./acast.component.scss']
})
export class AcastComponent implements OnInit {
  channelLinks;
  private readonly numberOfItems = 4;

  constructor(
    private acastService: AcastService,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    this.acastService.getPoddItems('juniorpodden').subscribe(res => {
      this.channelLinks = res.slice(0, this.numberOfItems).map(item => {
        let embedPath = new URL(item.link[0]).pathname;
        /**
         * Currently the embed path should be /name/episode-title not /s/name/episode-title.
         */
        if (embedPath.startsWith('/s')) {
          embedPath = embedPath.substr(2)
        }
        return this.sanitizer.bypassSecurityTrustResourceUrl(
          encodeURI(`https://embed.acast.com${embedPath}`)
        );
      });
    });
  }
}
