import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from '../../api.service';
import { SeoService } from '../../services';

@Component({
  selector: 'special',
  templateUrl: 'special.component.html',
  styleUrls: ['special.component.scss']
})
export class SpecialComponent implements OnInit {
  article: any;
  carouselActive = true;

  constructor(private route: ActivatedRoute, private API: APIService, private seoService: SeoService) {

  }

  ngOnInit () {
    this.route.params.subscribe(params => {
      this.fetchArticle(params['id']);
    })
  }

  fetchArticle (id) {
    this.carouselActive = false;
    this.API.GET('articles/special/' + id).subscribe(article => {
      this.article = article;

      this.seoService.setTitle(article.title + ' - Hockeymagasinet');
      this.carouselActive = true;
    })
  }
}
