import {Component} from '@angular/core';
import {APIService} from '../../api.service';
import {AdService, SeoService} from '../../services';

@Component({
  selector: 'home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss'],
})
export class HomeComponent {
  articles = [];
  latestArticles = [];
  offset = 0;
  articlesPerPage = 10;
  maxArticles = 25;
  canLoadMoreArticles = true;
  fetching = false;
  adKeys: { mobile: string[], desktop: string[] };
  articlesBetweenAds = 4;
  adRowsInserted = 0;
  insertedAds = {};

  constructor(private API: APIService, private seoService: SeoService, private adService: AdService) {
    seoService.setTitle('Hockeymagasinet - Juniorhockey');
    seoService.setMetaDescription('Sveriges största samlingsplats för svensk och internationell juniorhockey. Nyheter, åsikter, ' +
      'insikter och framtidsutsikter kring landets hockeyframtid i text');
    // Fetch latest articles
    this.fetchMoreArticles();
    this.adKeys = this.adService.getGroupKeys('flow');
  }

  fetchMoreArticles() {
    if (this.canLoadMoreArticles && !this.fetching) {
      this.fetching = true;
      let queryParameters = {
        offset: this.offset,
        limit: this.articlesPerPage
      };

      this.API.GET('articles/home', queryParameters).subscribe(articles => {
        this.fetching = false;
        this.articles = this.articles.concat(articles);
        this.latestArticles = this.getLatestArticles(articles);
        this.offset += this.articlesPerPage;
        if (articles.length < this.articlesPerPage || this.articles.length >= this.maxArticles) {
          this.canLoadMoreArticles = false;
        }
      }, error => {
        this.fetching = false;
      });
    }
  }

  private getLatestArticles(articles) {
    let latestArticles = [];
    for (let i = 0; i < articles.length; i++) {
      if (!articles[i].highlight.display && !articles[i].sponsored.display) {
        latestArticles.push(articles[i]);
      }
      if (latestArticles.length >= 4) {
        break;
      }
    }
    return latestArticles;
  }

  getNextAd(index) {
    if (!this.insertedAds[index]) {
      this.insertedAds[index] = {
        mobile: this.adKeys.mobile[this.adRowsInserted % this.adKeys.mobile.length],
        desktop: this.adKeys.desktop[this.adRowsInserted % this.adKeys.desktop.length]
      };
      this.adRowsInserted++;
    }
    return this.insertedAds[index];
  }
}
