import { Component, OnInit, ViewChild } from '@angular/core';
import { APIService } from '../../api.service';
import { SeoService } from '../../services';

@Component({
  selector: 'app-awards-list',
  templateUrl: './awards-list.component.html',
  styleUrls: ['./awards-list.component.scss']
})
export class AwardsListComponent {
  @ViewChild('anchor') anchor;

  awards = [];
  filters: {
    league: any;
    year: number;
  } = {
    league: {},
    year: 0
  };

  constructor(private API: APIService, private seoService: SeoService) {
    seoService.setTitle('Awards - Hockeymagasinet');
    seoService.setMetaDescription(
      'Tävling där Hockeymagasinet tar chansen att hylla de vi tycker har varit mest framträdande under den gångna säsongen i Juniorligan.'
    );

    API.GET('awards').subscribe(awards => {
      this.awards = awards;

      this.filters.league = awards[0].league;
      this.filters.year = awards[0].year;
    });
  }

  getPhoto(year) {
    let photo = '';

    this.awards.map(award => {
      if (award.year === year) {
        photo = award.player.image;
      }
    });

    return photo;
  }

  scrollToTop() {
    this.anchor.nativeElement.scrollIntoView({
      block: 'end',
      behavior: 'smooth'
    });
  }

  setYear(year) {
    this.filters.year = year;
  }

  setLeague(league) {
    this.filters.league = league;

    let year = Math.max.apply(Math, this.getYearList());
    this.filters.year = year;
  }

  // Returns a list of awards filtered by 'league' and 'year'
  getFilteredItems() {
    if (!this.awards.length) {
      return null;
    }

    let items = this.awards.filter(award => {
      let valid = true;

      if (this.filters.league.id !== award.league.id) {
        valid = false;
      }

      if (this.filters.year !== award.year) {
        valid = false;
      }

      return valid;
    });

    return items;
  }

  getCurrentItem() {}

  // Get all unique leagues
  getLeagueList() {
    let list = [];

    this.awards.map(award => {
      if (!award.league) {
        return [];
      }

      if (!list.filter(item => item.id === award.league.id).length) {
        list.push(award.league);
      }
    });

    return list;
  }

  getYearList() {
    let list = [],
      items = this.awards.filter(award => {
        let valid = true;

        if (this.filters.league.id !== award.league.id) {
          valid = false;
        }

        return valid;
      });

    items.map(award => {
      if (!list.filter(item => item === award.year).length) {
        list.push(award.year);
      }
    });

    return list.sort((a, b) => a - b);
  }
}
