import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {Router, ActivatedRoute, Route} from '@angular/router';
import { APIService } from '../../api.service';
import { SeoService } from '../../services';

import * as moment from 'moment';
import 'moment/locale/sv';

@Component({
  selector: 'search',
  templateUrl: 'search.component.html',
  styleUrls: ['search.component.scss']
})
export class SearchComponent implements OnInit {
  @ViewChild('anchor') anchor;

  query: string;
  results: any;
  text: string;
  searching = false;
  hasResults = true;
  sorting = 'relevance';

  page = 0;
  pages: any[];

  gcsesearch: SafeHtml;

  constructor(private API: APIService,
      private route: ActivatedRoute,
      private router: Router,
      private seoService: SeoService,
      private sanitizer: DomSanitizer) {
    moment.locale('sv');

    this.seoService.setTitle('Sök - Hockeymagasinet');
    this.seoService.setMetaDescription('Sök efter taggar, artiklar och blogginlägg');
  }

  ngOnInit () {
    this.route.params.subscribe(params => {
      this.query = params['query'];

      this.text = this.query;
      this.performSearch();
    })
    // this.gcsesearch = this.sanitizer.bypassSecurityTrustHtml('<gcse:search></gcse:search>');
    // let cx = '000981802584527938014:dbm-lozztpq';
    // let gcse = document.createElement('script');
    // gcse.type = 'text/javascript';
    // gcse.async = true;
    // gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
    // let s = document.getElementsByTagName('script')[0];
    // s.parentNode.insertBefore(gcse, s);
  }

  performSearch () {
    this.searching = true;
    this.pages = [];

    this.API.GET('search', {
      searchParams: this.text
    }).subscribe(results => {
      this.hasResults = <boolean>results.length;

      this.results = results;
      this.generatePages(results);

      this.searching = false;
    }, () => this.searching = false)
  }

  onSubmit () {
    // Assume the user only changed the sorting type (relevans, datum)
     if (this.text === this.query) {
       this.performSearch()
     } else {
      this.router.navigate(['/search', this.text])
     }

  }

  setPage (page): void {
    if (page < 0) {
      this.page = 0
    } else if (page > this.pages.length) {
      this.page = this.pages.length
    } else {
      this.page = page
    }

    // Scroll to top of page
    this.anchor.nativeElement.scrollIntoView({
      block: 'end',
      behavior: 'smooth'
    })
  }

  generatePages (items): void {
    if (this.sorting === 'date') {
      items.sort((a, b) => {
        return +(new Date(b.published)) - +(new Date(a.published))
      })
    }

    const PAGE_MAX = 10;
    let page = 0;

    this.pages = [];

    while (items.length > 0) {
      this.pages[page] = [];
      this.pages[page].push(...items.splice(0, PAGE_MAX));
      page++
    }
  }

  getTextOverview (text) {
    text = this.API.trimHTML(text);

    return text.split(' ').splice(0, 40).join(' ')
  }

  getPrettyTimestamp (timestamp) {
    return moment(timestamp).format('LLL')
  }

  getRouteForItem (item): any { // Route {
    if (item.type === 'blogPost') {
      return ['/blogs', item.blogSlug, item.slug];
    } else if (item.type === 'article') {
      return ['/article', item.slug];
    } else {
      console.error('Unknown item type: ' + item.type);
      return ['/'];
    }
  }
}
