import { Component, Input } from '@angular/core';

@Component({
  selector: 'highlight',
  styleUrls: ['highlight.component.scss'],
  template: `
    <div class="highlight" *ngIf="highlight.display"
      [ngClass]="{'theme-light': highlight.theme === 'light', 'theme-dark': highlight.theme === 'dark', 'theme-award': highlight.theme === 'award'}">
      {{ highlight.text }}
    </div>`,
})
export class HighlightComponent {
  @Input() highlight: any;

  constructor () {

  }
}
