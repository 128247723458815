import { Component, AfterViewInit } from '@angular/core';

@Component({
  selector: 'strossle',
  templateUrl: './strossle.component.html',
  styleUrls: ['./strossle.component.css']
})
export class StrossleComponent implements AfterViewInit {
  constructor() {}
  ngAfterViewInit() {
    window.strossle('e951dc4c-53d4-407e-ac46-1318e2a070c8', '.strossle-widget');
  }
}
