import { Injectable } from '@angular/core';

@Injectable()
export class CookieService {

  // cookieName = 'hockeymagasinet';
  // value = 'true';

  constructor() { }

  createCookie(cookieName, cookieValue = 'true', days = 1) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = cookieName + '=' + cookieValue + expires + '; path=/';
    console.log('Creating cookie:', cookieName + '=' + cookieValue + expires + '; path=/');
  }

  readCookie(cookieName) {
    const nameEQ = cookieName + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }
}

