import { Injectable } from '@angular/core';
import { Router, NavigationStart, Event as NavigationEvent } from '@angular/router';
import { APIService } from '../api.service';
import { environment } from '../../environments/environment';

declare var Notification: any;
declare var navigator: any;


@Injectable()
export class PushService {
  private PUSH_URL:string;
  private ENVIRONMENT_URLS = {
    development: 'https://push.hockey.dohi.agency/',
    staging: 'https://push.hockey.dohi.agency/',
    production: 'https://push.hockeymagasinet.com/'
  };
  private SW_PATH = '/sw.js';
  public isPushEnabled:boolean = false;
  public isPushLoading:boolean = false;

  constructor(private API: APIService) {
    this.PUSH_URL = this.ENVIRONMENT_URLS[environment.env];
    if (this.isEligibleForNotifications()) {
      navigator.serviceWorker.getRegistration(this.SW_PATH).then(registration => {
        this.isPushEnabled = <boolean>registration;
      })
    }
  }


  showExampleNotification (registration) {
    registration.showNotification('Grattis!', {
      body: 'Notiser från Hockeymagasinet påslagna!',
      icon: window.location.origin + '/assets/favicon/android-icon-192x192.png'
    })
  }

  isEligibleForNotifications () {
    return 'serviceWorker' in navigator && 'Notification' in window && 'PushManager' in window;
  }

  hasNotificationPermissions () {
    if (this.isEligibleForNotifications()) {
      return Notification.permission !== 'denied';
    }
  }

  registerServices () {
    this.isPushLoading = true;

    Notification.requestPermission().then(result => {
      if (result === 'granted') {

        this.registerServiceWorker()
      }

      else if (result === 'denied') {
        console.log('User denied permissions for Notifications!');
        this.isPushLoading = false;
      }
    }).catch(result => {
      console.warn('NEKAD PERMISSION', result);
      this.isPushLoading = false;
    })
  }

  disableServices () {
    navigator.serviceWorker.getRegistration(this.SW_PATH).then(registration => {
      registration.unregister().then(
        success => {
          console.log('ServiceWorker unregisteration succeeded!');
          this.isPushLoading = false;
          this.isPushEnabled = false;
        },
        error => {
          console.log('ServiceWorker unregistration failed!');
          this.isPushLoading = true;
        }
      )

    }).catch(result => {
      console.warn('GETREGISTRATION FAILED', result)
    })
  }


  registerDevice (subscription, registration) {
    let rawKey = subscription.getKey ? subscription.getKey('p256dh') : '';
    let key = rawKey ? btoa(String.fromCharCode.apply(null, new Uint8Array(rawKey))) : '';
    let rawAuthSecret = subscription.getKey ? subscription.getKey('auth') : '';
    let authSecret = rawAuthSecret ? btoa(String.fromCharCode.apply(null, new Uint8Array(rawAuthSecret))) : '';

    this.API.PostTo(this.PUSH_URL + 'register', {
      endpoint: subscription.endpoint,
      userPublicKey: key,
      userAuth: authSecret
    }).subscribe(
      success => {
        this.isPushEnabled = true;
        this.isPushLoading = false;
        this.showExampleNotification(registration);

        console.log('ServiceWorker registeration succeeded!')
      },
      error => {
        console.warn('Failed to communicate with server', error);
        this.isPushLoading = false;
      }
    )
  }

  registerServiceWorker () {
    let getRegistration = navigator.serviceWorker.register(this.SW_PATH).then(registration => {
      return Promise.resolve(registration)
    }).catch(result => {
      console.warn('Unable to fetch Service Worker Registration!', result);
      return this.isPushLoading = false;
    });


    getRegistration.then(registration => {

      navigator.serviceWorker.ready.then(registration => {
        registration.pushManager.subscribe({
          userVisibleOnly: true
        }).then(subscription => {
          let pushManager = registration.pushManager.subscribe({
            userVisibleOnly: true
          });

          pushManager.then(subscription => this.registerDevice(subscription, registration)).catch(result => {
            console.warn('pushmanager then FAILED', result);
            return this.isPushLoading = false;
          })
        }).catch(result => {
          console.warn('somesthin FAILED', result);
          return this.isPushLoading = false;
        })
      })
    }).catch(result => {
      console.warn('GETREGISTRATION2 FAILED', result);
      return this.isPushLoading = false;
    })
  }

}
