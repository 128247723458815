<h4>Artikel</h4>
<spinner *ngIf="!post"></spinner>

<div class="post">
  <carousel *ngIf="carouselActive" [options]="{items: 1, video: true, lazyLoad: false, loop: false,  margin: 10, stagePadding: 0, autoplay: true}">
    <div class="item" *ngFor="let image of post?.images">
      <img src="{{ image.url }}" />
      <div class="details">
        <span *ngIf="image.caption.length">{{ image.caption }}</span>
        <span *ngIf="image.photographer.length">Fotograf: {{ image.photographer }}</span>
      </div>
    </div>
    <div class="item" style="min-height: 300px" *ngFor="let video of getVideos()" >
      <a class="owl-video" href="{{ video }}"></a>
    </div>
  </carousel>

  <!-- <span class="team">---J20-Superelit Södra---</span> -->
  <h5 class="title">{{ post?.title }}</h5>
  <timestamp [time]="post?.published" [friendly]="false"></timestamp>

  <p *ngIf="post" class="text text--15 introduction" [innerHTML]="bypassSanitizeIntroduction(post.introduction)"></p>

  <sellbranch-ad adName='article-inside'></sellbranch-ad>
  <sellbranch-ad adName='mobil-2'></sellbranch-ad>

  <div class="text--container" *ngIf="post">

    <div class="factbox factbox__large" *ngIf="post.factBox.title !== ''">
      <h2 class="factbox--title">{{ post.factBox.title }}</h2>
      <p class="factbox--text" [innerHTML]="post?.factBox.text"></p>
    </div>
    <p class="text text--15" [innerHTML]="bypassSantinize(post.text)"></p>

    <div class="factbox factbox__small" *ngIf="post.factBox.title !== ''">
      <h2 class="factbox--title">{{ post.factBox.title }}</h2>
      <p class="factbox--text" [innerHTML]="post?.factBox.text"></p>
    </div>
    <app-article-footer [footerText]="footerText"></app-article-footer>

  </div>

  <div class="social">
    <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u={{ url }}"><i class="fa fa-facebook" aria-hidden="true"></i></a>
    <a target="_blank" href="https://twitter.com/home?status={{ url }}"><i class="fa fa-twitter" aria-hidden="true"></i></a>
  </div>

  <span class="text--16">Taggar</span>
  <section class="tags">
    <tag *ngFor="let tag of post?.tags" [text]="tag"></tag>
  </section>

  <author *ngIf="!post?.sponsored.display" [data]="post?.author"></author>
</div>

<div class="post-navigation">
  <span [routerLink]="['/article', previousLink?.slug]" title="{{previousLink?.slug}}" class="post-previous" *ngIf="previousLink?.slug?.length">Föregående</span>
  <span [routerLink]="['/article', nextLink?.slug]" title="{{nextLink?.slug}}" class="post-next" *ngIf="nextLink?.slug?.length">Nästa</span>
</div>

<!-- <app-notice></app-notice> -->
<sellbranch-ad adName='article-below'></sellbranch-ad>
<sellbranch-ad adName='mobil-3'></sellbranch-ad>
<!-- <ad identifier="div-gpt-ad-123456789-5"></ad> -->
<!-- <div *ngIf="post?.commentsEnabled" id="disqus_thread"></div>
<noscript *ngIf="post?.commentsEnabled">Please enable JavaScript to view the <a href="https://disqus.com/?ref_noscript">comments powered by Disqus.</a></noscript> -->
