<section class="carousel" *ngIf="posts.length">
  <div class="content">

    <div class="container" [style.transform]="'translate3d(' + (-index * 100 / posts.length) + '%, 0, 0)'" [style.width.%]="posts.length * 100">
      <h1 class="title" [style.width.%]="100 / posts.length" *ngFor="let post of posts" [routerLink]="['/blogs', post.blogSlug, post.slug]">{{ post.title }}</h1>
    </div>

    <h2 class="author"> {{ posts[index].author?.name }}</h2>
  </div>


  <div
    class="picture"
    *ngFor="let post of posts; let i = index"
    [style.background-image]="'url(' + post.author?.image + ')'"
    [class.visible]="index === i">
  </div>
</section>
