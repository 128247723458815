import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  HomeComponent,
  ArticleComponent,
  LeaguesComponent,
  BlogsComponent,
  AwardsComponent,
  SearchComponent,
  NationalComponent,
  AboutComponent,
  SpecialComponent,
  TermsComponent,
  PlayerOfTheMonthComponent
} from './views';

import {
  BlogListComponent,
  BlogPostComponent,
  BlogOverviewComponent,
  AwardsListComponent
} from './components';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'article',
    component: ArticleComponent
  },
  {
    path: 'article/:id',
    component: ArticleComponent,
    pathMatch: 'full'
  },
  {
    path: 'leagues',
    component: LeaguesComponent,
    pathMatch: 'full'
  },
  {
    path: 'leagues/:id',
    component: LeaguesComponent,
    pathMatch: 'full'
  },
  {
    path: 'national',
    component: NationalComponent,
    pathMatch: 'full'
  },
  {
    path: 'national/:id',
    component: NationalComponent,
    pathMatch: 'full'
  },
  {
    path: 'blogs',
    component: BlogsComponent,
    children: [
      {
        path: '',
        component: BlogListComponent
      },
      {
        path: ':blog',
        component: BlogOverviewComponent
      },
      {
        path: ':blog/:post',
        component: BlogPostComponent
      }
    ]
  },
  {
    path: 'awards',
    component: AwardsComponent,
    children: [
      {
        path: '',
        component: AwardsListComponent
      },
      {
        path: 'player-of-the-month',
        component: PlayerOfTheMonthComponent
      }
    ]
  },
  {
    path: 'search',
    component: SearchComponent,
    pathMatch: 'full'
  },
  {
    path: 'search/:query',
    component: SearchComponent,
    pathMatch: 'full'
  },
  {
    path: 'special/:id',
    component: SpecialComponent,
    pathMatch: 'full'
  },
  {
    path: 'casino',
    redirectTo: 'special/casino',
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class RoutingModule {}
