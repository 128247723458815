import { Component, Input, OnChanges } from '@angular/core';

import { RelatedService } from '../../services';

@Component({
  selector: 'related',
  templateUrl: 'related.component.html',
  styleUrls: ['related.component.scss']
})
export class RelatedComponent {

  constructor(public relatedService: RelatedService) {

  }
}
