<spinner *ngIf="!post"></spinner>

<div class="post" *ngIf="post" [class.sponsored]="post.sponsored.display">
  <div class="sponsored--notation" *ngIf="post.sponsored.display">
    <span class="ad" *ngIf="post.adMarking">{{ post.adMarking }}</span>   
    <span class="ad" *ngIf="!post.adMarking">Sponsrad artikel</span>   
  </div>

  <div class="image" *ngIf="post.images[0]" [style.background-image]="'url(' + post.images[0].url + ')'" [routerLink]="['/article', post.slug]">
    <highlight [highlight]="post.highlight"></highlight>
  </div>

  <div class="text">
    <h5 [routerLink]="['/article', post.slug]">{{ post.title }}</h5>
    <timestamp [time]="post.published" [friendly]="true"></timestamp>
    <p>
      <span class="text--desktop">{{ getTextOverview(post.introduction) }} </span>
      <span *ngIf="post.shorty" class="text--mobile">{{ getTextOverview(post.shorty) }} </span>
      <span *ngIf="!post.shorty" class="text--mobile">{{ getTextOverview(post.introduction, 60) }}... </span>
      <a class="show-more" [routerLink]="['/article', post.slug]">Läs mer</a></p>
  </div>
</div>
