<modal
  *ngIf="visible"
  (onHide)="onBackdropClick.emit()"
>
  <div modal-header>
    Hantering av personuppgifter
  </div>
  <p>
    Denna sida använder information som kan kopplas till dig som besökare, för att förbättra och anpassa upplevelsen.
    Mer information finns i våra <a
      [routerLink]="['/terms']"
      target="_blank"
    >användarvillkor</a>. Läs igenom informationen och klicka nedan om du samtycker.
  </p>
  <div
    class="button-container"
    modal-footer
  >
    <button
      class="button"
      (click)="onApprove.emit()"
    >
      Jag har läst informationen och samtycker
    </button>
  </div>
</modal>
