<div class="content">
  <div class="wrapper">
    <div class="main">
      <post [post]="post" [footerText]="footerText"></post>
      <strossle></strossle>
    </div>

    <div class="side">
      <related></related>
      <hottest-articles></hottest-articles>
      <!-- <app-notice></app-notice> -->
      <sellbranch-ad adName='insider-1'></sellbranch-ad>
      <sellbranch-ad adName='mobil-5'></sellbranch-ad>
      <!-- <ad identifier="div-gpt-ad-123456789-3"></ad> -->
      <bloggers></bloggers>
      <!-- <app-notice></app-notice> -->
      <sellbranch-ad adName='insider-2'></sellbranch-ad>
      <sellbranch-ad adName='mobil-6'></sellbranch-ad>
      <!-- <ad identifier="div-gpt-ad-123456789-4"></ad> -->
    </div>
  </div>

</div>
