<div class="content">
  <div class="wrapper">
    <div class="main">
      <h4>Artikel</h4>

      <carousel
        *ngIf="carouselActive"
        [options]="{items: 1, video: false, lazyLoad: false, loop: false,  margin: 10, stagePadding: 0, autoplay: true}"
      >
        <div
          class="item"
          *ngFor="let image of article?.images"
        >
          <img src="{{ image.url }}" />
          <div class="details">
            <span *ngIf="image.caption.length">{{ image.caption }}</span>
            <span *ngIf="image.photographer.length">Fotograf: {{ image.photographer }}</span>
          </div>
        </div>
      </carousel>

      <h5 class="title">{{ article?.title }}</h5>
      <p
        class="text text--15"
        [innerHTML]="article?.text"
      ></p>

    </div>


    <div class="side">
      <hottest-articles></hottest-articles>
      <!-- <app-notice></app-notice> -->
      <sellbranch-ad adName='insider-1'></sellbranch-ad>
      <sellbranch-ad adName='mobil-2'></sellbranch-ad>
      <!-- <ad identifier="div-gpt-ad-123456789-3"></ad> -->
      <bloggers></bloggers>
      <!-- <app-notice></app-notice> -->
      <sellbranch-ad adName='insider-2'></sellbranch-ad>
      <sellbranch-ad adName='mobil-3'></sellbranch-ad>
      <!-- <ad identifier="div-gpt-ad-123456789-4"></ad> -->
    </div>
  </div>

</div>
