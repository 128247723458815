import {
  Component,
  OnInit,
  AfterViewInit,
  Output,
  EventEmitter,
  OnDestroy,
  Renderer2
} from '@angular/core';
import { CookieService } from '../../services';

@Component({
  selector: 'interstitial-modal',
  templateUrl: './interstitial-modal.component.html',
  styleUrls: ['./interstitial-modal.component.scss']
})
export class InterstitialModalComponent implements OnDestroy {
  private _visible = true;
  @Output() loaded: EventEmitter<any> = new EventEmitter();

  constructor(
    private renderer: Renderer2,
    private cookieService: CookieService
  ) {}

  public get visible() {
    return this._visible;
  }

  public set visible(isVisible: boolean) {
    if (isVisible) {
      this.setModalOpen();
    } else {
      this.removeModalOpen();
    }

    this._visible = isVisible;
  }

  ngOnDestroy(): void {
    this.removeModalOpen();
  }

  public show(): void {
    this.visible = true;
  }

  close() {
    this.visible = false;
    this.removeModalOpen();
    this.cookieService.createCookie('hm_interstitial', 'true');
  }

  isLoaded(isLoaded) {
    if (isLoaded) {
      this.setModalOpen();
    } else {
      this.removeModalOpen();
    }
    this.loaded.emit(isLoaded);
  }

  private setModalOpen() {
    this.renderer.addClass(document.body, 'modal-open');
  }

  private removeModalOpen() {
    this.renderer.removeClass(document.body, 'modal-open');
  }
}
