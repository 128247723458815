import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Parser } from 'xml2js';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class AcastService {
  private readonly acastUrl: string;
  private parser: Parser;

  constructor(private http: HttpClient) {
    this.parser = new Parser();
    this.acastUrl = 'https://rss.acast.com/';
  }

  getPoddItems(channelName): Observable<[{ link: string }]> {
    const options = { responseType: 'text' as 'text' };
    return this.http
      .get(this.acastUrl + channelName, options)
      .pipe(mergeMap(res => this.parseChannelXML(res, channelName)));
  }

  /**
   * Get the channel items from the channel XML
   * @param XML
   * @param channelName
   */
  private parseChannelXML(XML: string, channelName: string) {
    return Observable.create(observer => {
      this.parser.parseString(XML, (err, result) => {
        if (err) {
          observer.error(err);
        }

        const channel = result.rss.channel[0];
        if (typeof channel === 'undefined') {
          observer.error(
            `The Acast channel could not be found: ${channelName}`
          );
        }
        observer.next(channel.item);
        observer.complete();
      });
    });
  }
}
