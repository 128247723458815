import { NgModule } from '@angular/core';
import { AppComponent } from './';

import { RoutingModule } from './app.routing';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';

import { APIService } from './api.service';
import {
  RelatedService,
  MenuService,
  PushService,
  AdService,
  SeoService,
  CookieService,
  PrivacyService,
  AcastService
} from './services';

import { SmallPipe, MediumPipe } from './pipes';

// Ugly, but we need it for AoT (ahead-of-time compilation)
import {
  ArticleCarouselComponent,
  BlogInlineComponent,
  BlogListComponent,
  BlogOverviewComponent,
  BlogPostComponent,
  BloggersComponent,
  CardComponent,
  CarouselComponent,
  ChartComponent,
  DivisionNewsComponent,
  FooterComponent,
  HeaderComponent,
  HeaderCarouselComponent,
  HighlightComponent,
  HottestArticlesComponent,
  PostComponent,
  PostInlineComponent,
  TagComponent,
  RelatedComponent,
  SpinnerComponent,
  AuthorComponent,
  PushButtonComponent,
  TimestampComponent,
  SellbranchAdComponent,
  ModalComponent,
  GdprAlertComponent,
  ArticleFooterComponent,
  StrossleComponent,
  AcastComponent,
  AwardsListComponent,
  PlayerOfTheMonthListComponent,
  AwardComponent,
  InterstitialModalComponent
} from './components';

import { InfiniteScrollDirective, StickyDirective } from './directives';

import {
  AboutComponent,
  ArticleComponent,
  AwardsComponent,
  BlogsComponent,
  HomeComponent,
  LeaguesComponent,
  SearchComponent,
  NationalComponent,
  SpecialComponent,
  TermsComponent,
  PlayerOfTheMonthComponent
} from './views';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    RoutingModule,
    HttpClientModule
  ],
  declarations: [
    // Components
    ArticleCarouselComponent,
    BlogInlineComponent,
    BlogListComponent,
    BlogOverviewComponent,
    BlogPostComponent,
    BloggersComponent,
    CardComponent,
    CarouselComponent,
    ChartComponent,
    DivisionNewsComponent,
    FooterComponent,
    HeaderComponent,
    HeaderCarouselComponent,
    HighlightComponent,
    HottestArticlesComponent,
    PostComponent,
    PostInlineComponent,
    TagComponent,
    RelatedComponent,
    SpinnerComponent,
    AuthorComponent,
    PushButtonComponent,
    TimestampComponent,
    SellbranchAdComponent,
    ModalComponent,
    SellbranchAdComponent,
    ModalComponent,
    GdprAlertComponent,
    ArticleFooterComponent,
    GdprAlertComponent,
    ArticleFooterComponent,
    StrossleComponent,
    AcastComponent,
    AwardsListComponent,
    PlayerOfTheMonthListComponent,
    AwardComponent,
    InterstitialModalComponent,

    // Views
    AboutComponent,
    ArticleComponent,
    AwardsComponent,
    BlogsComponent,
    HomeComponent,
    LeaguesComponent,
    SearchComponent,
    NationalComponent,
    SpecialComponent,
    TermsComponent,
    PlayerOfTheMonthComponent,

    // Directives
    StickyDirective,
    InfiniteScrollDirective,

    // Pipes
    SmallPipe,
    MediumPipe,

    AppComponent
  ],
  bootstrap: [AppComponent],
  providers: [
    APIService,
    RelatedService,
    MenuService,
    PushService,
    AdService,
    SeoService,
    CookieService,
    PrivacyService,
    AcastService,
  ]
})
export class AppModule {}
