import { Component, OnInit, Input } from '@angular/core';
import { APIService } from '../../api.service';

@Component({
  selector: 'app-article-footer',
  templateUrl: './article-footer.component.html',
  styleUrls: ['./article-footer.component.scss']
})
export class ArticleFooterComponent implements OnInit {

  @Input() footerText: string;

  constructor() { }

  ngOnInit() {
  }

}
