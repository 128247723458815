<div
  [ngClass]="{'removed': termsAreLoaded,'is-interstitial':isInterstitial,'not-loaded':!isLoaded}"
  class="sellbranch-ad__placeholder placeholder"
  [ngStyle]="placeholderDimensions"
  *ngIf="adWasCreated"
>
  <div
    id="{{ mapping.identifier }}-container"
    class="foreground"
  >
    <div
      sticky
      #ad
      class="ad"
      [ngClass]="{'not-loaded':!isLoaded,'is-fixable':mapping.fixable}"
      [isFixable]="mapping.fixable"
      [isReleasable]="mapping.releasable"
      [stickyTime]="2000"
    >
      <div class="notice">
        <span class="text">Annons</span>
      </div>
      <div
        id="{{ mapping.identifier }}"
        class=" ad-container"
      >
      </div>
    </div>
  </div>
</div>
