import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from '../../api.service';
import { RelatedService, SeoService } from '../../services/';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'blog-post',
  templateUrl: 'blog.post.component.html',
  styleUrls: ['blog.post.component.scss']
})
export class BlogPostComponent implements OnInit {
  carouselActive: boolean = true;
  post: any;
  slug: any;
  bypassed: any;

  constructor(private sanitizer: DomSanitizer, private route: ActivatedRoute, private API: APIService, private relatedService: RelatedService, private seoService: SeoService) {

  }

  setSeoDetails (post) {
    let text = this.API.trimHTML(post.text);
    text = text.split(' ', 30).join(' ') + '...';

    this.seoService.setTitle(post.title + ' - Hockeymagasinet');
    this.seoService.setMetaDescription(text);

    if (!post.images.length) return;
    this.seoService.setImage(post.images[0].url)
  }

  isYoutubeLink (url) {
    let query = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (url.match(query)){
        return url.match(query)[1];
    }
    return false;
  }

  getVideos () {
    if (this.post) {
      return this.post.videos.filter(url => this.isYoutubeLink(url))
    }
  }

  // The carousel won't update when we fetch it new data,
  // so we have to force reload the whole component
  resetCarousel () {
    this.carouselActive = false;
    setTimeout(() => this.carouselActive = true)
  }

  bypassSanitize(html) {
    if(!this.bypassed) {
      this.bypassed = this.sanitizer.bypassSecurityTrustHtml(html);
    }
    return this.bypassed;
  }

  ngOnInit () {
    this.route.params.subscribe(params => {
      this.slug = params['post'];

      this.API.GET('blogs/posts/' + this.slug).subscribe(post => {
        this.post = post;
        this.enableDisqus(post.blogSlug, post.slug);
        this.bypassed = null;
        this.relatedService.setTags(post.tags, post.id);
        this.resetCarousel();

        this.setSeoDetails(post)
        this.attachTwitterScript();
      })
    })
  }

  enableDisqus (blogId, postId) {
      var disqus_config = function () {
          this.page.url = '/blogs/' + blogId + '/' + postId;
          this.page.identifier = blogId + '/' + postId;
      };

      (function() {
          var d = document, s = d.createElement('script');
          s.src = '//hockeymagasinet-new.disqus.com/embed.js';
          s.setAttribute('data-timestamp', String(+new Date()));
          (d.head || d.body).appendChild(s);
      })();
  }

  attachTwitterScript() {
    let s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://platform.twitter.com/widgets.js';
    document.body.appendChild(s);
  }
}
