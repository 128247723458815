<div class="related" *ngIf="relatedService.getTags() !== null || relatedService.getTags()?.length === 0">
  <h4>Relaterat</h4>
  <spinner *ngIf="!relatedService.getRelatedPosts()?.length"></spinner>

  <div *ngFor="let post of relatedService.getRelatedPosts()">
    <div class="post" *ngIf="post?.title?.length && post?.images?.length">
      <div class="picture" *ngIf="post.type === 'article'"  [routerLink]="['/article', post.slug]" [style.background-image]="'url(' + (post.images[0].url | medium) + ')'"></div>
      <div class="picture" *ngIf="post.type === 'blogPost'"  [routerLink]="['/blogs', post.blog, post.slug]" [style.background-image]="'url(' + (post.images[0].url | medium) + ')'"></div>
      <span class="title" *ngIf="post.type === 'article'"  [routerLink]="['/article', post.slug]">{{ post.title }}</span>
      <span class="title" *ngIf="post.type === 'blogPost'" [routerLink]="['/blogs', post.blog, post.slug]">{{ post.title }}</span>
    </div>
  </div>
</div>
