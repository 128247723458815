import { Component, ViewChild, ElementRef } from '@angular/core';
import { APIService } from '../../api.service';

@Component({
  selector: 'footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss']
})
export class FooterComponent {
  currentYear = new Date().getFullYear();
  text: any;

  @ViewChild('text') dataContainer: ElementRef;

  constructor(private API: APIService) {
    API.GET('footer/ad').subscribe(data => {
      this.text = data.text;
      this.dataContainer.nativeElement.innerHTML = this.text;
    })
  }
}
