<spinner *ngIf="!blog"></spinner>

<div class="blog" *ngIf="blog" [class.disabled]="!shouldLink" [routerLink]="['/blogs', blog.slug]">
  <div class="blog--top">
    <h5 class="name">{{ blog.title }}</h5>
  </div>

  <div class="blog--image"><img [src]="bypassSanitize(blog.image)"></div>

  <div class="blog--bottom">
    <h5 class="author">{{ blog.owner.name }}</h5>
    <h3 class="about">{{ API.trimHTML(blog.description) }}</h3>
  </div>
</div>
