<h4>Blogg</h4>
<spinner *ngIf="!blog"></spinner>

<blog-inline [shouldLink]="false" [blog]="blog"></blog-inline>

<div class="author">
  <div class="description" [innerHTML]="blog?.owner?.description"></div>
</div>

<div class="posts">
  <div class="item" *ngFor="let post of blog?.posts">
    <h5 class="title" [routerLink]="['/blogs', blog.slug, post.slug]">{{ post.title }}</h5>
    <timestamp [time]="post.published" [friendly]="true"></timestamp>

    <div class="text">{{ getTextOverview(post.text) }} <a class="show-more" [routerLink]="['/blogs', blog.slug, post.slug]">Läs mer</a></div>

    <div class="tags">
      <tag *ngFor="let tag of post?.tags" [text]="tag"></tag>
    </div>
  </div>
</div>

<div class="no-posts" *ngIf="blog?.posts?.length === 0">
  {{ blog.owner.name }} har inte publiserat några inlägg ännu
</div>
