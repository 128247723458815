import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { APIService } from '../../api.service';

@Component({
  selector: 'post',
  templateUrl: 'post.component.html',
  styleUrls: ['post.component.scss']
})
export class PostComponent implements OnInit, OnChanges {
  @Input() post: any;
  @Input() footerText: string;
  carouselActive: boolean = true;
  isDisqusEnabled: boolean = false;
  bypassed:any;
  url:string;
  nextLink:any;
  previousLink:any;
  bypassedIntroduction: any;

  constructor (private sanitizer: DomSanitizer, private API: APIService) {

  }

  ngOnChanges () {
    // Wait for post to be set before enabling disqus
    if (this.post) {
      this.bypassed = null;
      this.bypassedIntroduction = null;
      // if(!this.isDisqusEnabled) {
      //   this.enableDisqus(this.post);
      // }
      this.getNavigationLinks(this.post.id);
      this.attachTwitterScript();
    }
    this.resetCarousel();
  }

  attachTwitterScript() {
    let s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://platform.twitter.com/widgets.js';
    document.body.appendChild(s);
  }

  ngOnInit () {
    this.url = window.location.href;
  }

  isYoutubeLink (url) {
    let query = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (url.match(query)){
        return url.match(query)[1];
    }
    return false;
  }

  getVideos () {
    if (this.post) {
      return this.post.videos.filter(url => this.isYoutubeLink(url))
    }
  }

  // The carousel won't update when we fetch it new data,
  // so we have to force reload the whole component
  resetCarousel () {
    this.carouselActive = false;
    setTimeout(() => this.carouselActive = true)
  }

  bypassSantinize (html) {
    if (!this.bypassed) {
      this.bypassed = this.sanitizer.bypassSecurityTrustHtml(html)
    }

    return this.bypassed
  }

  bypassSanitizeIntroduction (html) {
    if (!this.bypassedIntroduction) {
      this.bypassedIntroduction = this.sanitizer.bypassSecurityTrustHtml(html)
    }

    return this.bypassedIntroduction;
  }

  enableDisqus (article) {
      this.isDisqusEnabled = true;

      var disqus_config = function () {
          this.page.url = '/article/' + article.slug;
          this.page.identifier = article.slug;
      };

      (function() {
          var d = document, s = d.createElement('script');
          s.src = '//hockeymagasinet-new.disqus.com/embed.js';
          s.setAttribute('data-timestamp', String(+new Date()));
          (d.head || d.body).appendChild(s);
      })();
  }

  getNavigationLinks(id) {
    this.previousLink = undefined;
    this.nextLink = undefined;

    this.API.GET('articles/previous/' + id).subscribe(result => {
      this.previousLink = result;
    });

    this.API.GET('articles/next/' + id).subscribe(result => {
      this.nextLink = result;
    });
  }
}
