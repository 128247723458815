<spinner *ngIf="!articles"></spinner>

<carousel *ngIf="articles" [options]="{items: 1, video: false, lazyLoad: false, loop: true,  margin: 10, stagePadding: 0, autoplay: true}">
  <div class="item" *ngFor="let article of getValidArticles()">

    <div class="image" [style.background-image]="'url(' + (article.featuredImages?.length ? article.featuredImages[0].url : article.images[0].url) + ')'" [routerLink]="['/article', article.slug]">
      <highlight [highlight]="article.highlight"></highlight>
    </div>

    <div class="text">
      <h5 [routerLink]="['/article', article.slug]">{{ ( article.featuredTitle?.length ? article.featuredTitle : article.title) }}</h5>
    </div>
  </div>
</carousel>
