import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {APIService} from '../../api.service';
import {RelatedService, SeoService} from '../../services';

@Component({
  selector: 'article',
  templateUrl: 'article.component.html',
  styleUrls: ['article.component.scss']
})
export class ArticleComponent implements OnInit {
  post: any;
  footerText: string;

  constructor(private route: ActivatedRoute,
              private API: APIService,
              private relatedService: RelatedService,
              private seoService: SeoService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.fetchArticle(params['id']);
    });
    this.fetchFooterText();
  }

  fetchArticle(id) {
    this.API.GET('articles/' + id).subscribe(post => {
      if (post) {
        this.post = post;

        this.registerClick(post.id);

        this.relatedService.setTags(post.tags, post.id);

        this.seoService.setTitle(post.title + ' - Hockeymagasinet');
        this.seoService.setMetaDescription(this.API.trimHTML(post.introduction));

        if (!post.images.length) {
          return;
        }
        this.seoService.setImage(post.images[0].url);
      }
    });
  }

  registerClick(id) {
    this.API.PUT('articles/' + id + '/clicks', undefined).subscribe(result => {
      // Do nothing
    });
  }

  fetchFooterText() {
    this.API.GET('articles/footer').subscribe(footer => {
      if (typeof footer !== 'undefined') {
        this.footerText = footer.content;
      }
    })
  }
}
